import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { QRCodeSVG } from 'qrcode.react';
import CopyToClipboardTooltip from '../../../copyToClipboardTooltip';
import './download_pdf_modal.css';

class DownloadPdfModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <Button type="link" onClick={this.showModal}>
          Download
        </Button>
        <Modal
          title="Download PDF"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[
            <CopyToClipboardTooltip text={this.props.url}>
              <Button>Copy to clipboard</Button>
            </CopyToClipboardTooltip>,
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>
          ]}
        >
          <div className='flex justify-content-center'>
            <QRCodeSVG value={this.props.url} size="H"/>
          </div>
        </Modal>
      </div>
    );
  }
}

DownloadPdfModal.propTypes = {
  url: PropTypes.string,
};

export default DownloadPdfModal;
