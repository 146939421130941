import React, { Component } from 'react';
import { Drawer, Button, Spin } from 'antd';
import MenuBooking from '../menu';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import API from '../../../../api';
import './index.css';


class DedicatedPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      booking_id: null,
      visible: false,
      loading: true,
      credits: 0,
      credit_disabled: true,
      credit_transaction_disabled: false,
      payment_amount: 0,
      total_price: 0,
      balance_amount: 0,
      set_amount: 0,
      payment_type: '',
      fare_type_selected: '',
      collect_type: '',
    }
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  getCredits = (value, isCreditTransactionDisabled = false) => {
    this.setState({
      credits: value,
      credit_transaction_disabled: isCreditTransactionDisabled
    }, () => { this.handleUserCredits(); })
  };

  handleUserCredits = () => {
    if (this.state.credit_transaction_disabled) {
      return this.setState({
        credit_disabled: true
      })
    }

    if (this.state.credits && this.state.credits >= Number((this.state.payment_amount).toString().replace(/[^0-9\.-]+/g, ""))) {
      this.setState({
        credit_disabled: false
      })
    }
    else {
      this.setState({
        credit_disabled: true
      })
    }
  }

  handleCustomerDeposit = (value) => {
    this.setState({
      fare_type_selected: value
    }, () => { this.handleDeposit(value) })
  };

  handleSetAmountToPay = (value) => {
    this.setState({
      set_amount: value,
      payment_amount: value
    })
  };

  handleDeposit = (value) => {
    let payment_amount = 0;
    let total_price = 0;
    let balance_amount = 0;
    let set_amount = 0;
    let collect_type = '';

    if (this.state.data && this.state.data.booked_from == "travelb2bsg"){
      if (value === "customer_deposit") {
        payment_amount = this.state.data && (this.state.data.currency_deposit + this.state.data.currency_extra_deposit)
        total_price = this.state.data && this.state.data.currency_customer_handling_price
        balance_amount = this.state.data && (this.state.data.currency_balance_customer_price - this.state.data.currency_paid_amount)
        collect_type = "deposit"
      }
      else if (value === "agent_deposit") {
        payment_amount = this.state.data && this.state.data.currency_deposit
        total_price = this.state.data && this.state.data.currency_handling_price
        balance_amount = this.state.data && (this.state.data.currency_balance_amount - this.state.data.currency_paid_amount)
        collect_type = "deposit"
      }
      else if (value === "customer_full_fare") {
        balance_amount = this.state.data && this.state.data.currency_balance_customer_price
        payment_amount = this.state.data && (this.state.data.currency_customer_handling_price - this.state.data.currency_paid_amount)
        total_price = this.state.data && this.state.data.currency_customer_handling_price
        collect_type = "balance"
      }
      else if (value === "agent_full_fare") {
        balance_amount = this.state.data && (this.state.data.currency_handling_price - this.state.data.currency_paid_amount)
        payment_amount = this.state.data && this.state.data.currency_balance_amount
        total_price = this.state.data && this.state.data.currency_handling_price
        collect_type = "balance"
      }
      else {
        payment_amount = this.state.data && (this.state.data.fare_type === 'deposit' ? this.state.data.currency_deposit : this.state.data.currency_balance_amount)
        balance_amount = this.state.data && (this.state.data.currency_handling_price - this.state.data.currency_paid_amount)
        total_price = this.state.data && this.state.data.currency_handling_price
        collect_type = this.state.data && this.state.data.fare_type
      }
    }
    else {
      if (value === "customer_deposit") {
        payment_amount = this.state.data && (this.state.data.deposit + this.state.data.extra_deposit)
        total_price = this.state.data && this.state.data.customer_handling_price
        balance_amount = this.state.data && (this.state.data.balance_customer_price - this.state.data.paid_amount)
        collect_type = "deposit"
      }
      else if (value === "agent_deposit") {
        payment_amount = this.state.data && this.state.data.deposit
        total_price = this.state.data && this.state.data.handling_price
        balance_amount = this.state.data && (this.state.data.balance_amount - this.state.data.paid_amount)
        collect_type = "deposit"
      }
      else if (value === "customer_full_fare") {
        balance_amount = this.state.data && this.state.data.balance_customer_price
        payment_amount = this.state.data && (this.state.data.customer_handling_price - this.state.data.paid_amount)
        total_price = this.state.data && this.state.data.customer_handling_price
        collect_type = "balance"
      }
      else if (value === "agent_full_fare") {
        balance_amount = this.state.data && (this.state.data.handling_price - this.state.data.paid_amount)
        payment_amount = this.state.data && this.state.data.balance_amount
        total_price = this.state.data && this.state.data.handling_price
        collect_type = "balance"
      }
      else {
        payment_amount = this.state.data && (this.state.data.fare_type === 'deposit' ? this.state.data.deposit : this.state.data.balance_amount)
        balance_amount = this.state.data && (this.state.data.handling_price - this.state.data.paid_amount)
        total_price = this.state.data && this.state.data.handling_price
        collect_type = this.state.data && this.state.data.fare_type
      }
    }

    this.setState({
      payment_amount: payment_amount,
      total_price: total_price,
      balance_amount: balance_amount,
      collect_type: collect_type,
      set_amount: set_amount
    });
  };

  componentDidMount() {
    // this.apiFetch();
    console.log(this.props.location.pathname)
    var split_url = this.props.location.pathname.split('/').filter(item => item)
    let booking_id = this.props.location.pathname.match(/\d+/);
    var product_type = split_url[1];
    var that = this;
    var url = './api/v1/'+product_type+'/'+booking_id+'/new_payment';
    var params = this.props.location.search;

    API.get(url + params)
    .then(function (response) {
        // console.log(response,'------------res');
        if(response.data.error){
          alert(response.data.error)
          window.location.href = '#/bookings/series';
        }
        else {
          var payment_amount = 0;
          var total_price = 0;
          var balance_amount = 0;

          if (response.data.booked_from == "travelb2bsg") {
            payment_amount = response.data.fare_type === 'deposit' ? response.data.currency_deposit : response.data.currency_balance_amount;
            total_price = response.data.currency_handling_price;
            balance_amount = response.data.currency_handling_price - response.data.currency_paid_amount;
          }
          else {
            payment_amount = response.data.fare_type === 'deposit' ? response.data.deposit : response.data.balance_amount;
            total_price = response.data.handling_price;
            balance_amount = response.data.handling_price - response.data.paid_amount;
          }
          

          if (payment_amount > 0){
            that.setState({
              data: response.data,
              loading: false,
              booking_id: booking_id,
              product_type: product_type,
              payment_amount: payment_amount,
              total_price: total_price,
              collect_type: response.data.fare_type,
              balance_amount: balance_amount,
            })
          }
          else {
            alert("The payment is paid.")
            window.location.href = '#/bookings/series';
          }
        }
        
    })
    .catch(function (error) {
        console.log(error);
    })
    .then(function () {
        // always executed
    });
  }

  handlePaymentType = (value) => {
    this.setState({
      payment_type: value
    });
  };

  render() {
    const { loading, data, credit_disabled, credits, product_type, payment_amount, payment_type, total_price, balance_amount, collect_type, set_amount } = this.state;

    var products = {
      "land_tours": "land_tour",
      "series": "series",
    }

    var service_fee = data !== null ? data.cc_rate * payment_amount : 0

    return (
      <div id="DedicatedPayment">
        <MenuBooking selected={products[product_type]} />
        <Spin size="large" spinning={loading}>
          <div className="warppage">
            <LeftPanel loading={loading} data={data} getCredits={this.getCredits} credits={credits} credit_disabled={credit_disabled} product_type={product_type} handlePaymentType={this.handlePaymentType} handleCustomerDeposit={this.handleCustomerDeposit} payment_amount={payment_amount} total_price={total_price} balance_amount={balance_amount} collect_type={collect_type} service_fee={service_fee} handleSetAmountToPay={this.handleSetAmountToPay} set_amount={set_amount} handleDeposit={this.handleDeposit} />
            <RightPanel loading={loading} data={data} payment_type={this.state.payment_type} service_fee={service_fee} payment_amount={payment_amount} set_amount={set_amount} />
            <div className="clear"></div>
          </div>
          <div className="res_panel">
            <Button type="primary" onClick={this.showDrawer} className="res_panel_btn">
              Summary
           </Button>
            <Drawer
              title=""
              placement="right"
              closable={true}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <RightPanel loading={loading} data={data}/>
            </Drawer>
          </div>
        </Spin>
      </div>
    );
  }
}

export default DedicatedPayment;