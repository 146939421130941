import React, { Component } from 'react';
import { Rate, Card, List, Avatar, Collapse, Input, Select, Button, Popover, Checkbox, Skeleton, Form, notification, InputNumber, Tooltip, Modal, Empty, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import { Link, withRouter } from 'react-router-dom';
import countryList from 'react-select-country-list';
import qs from 'qs';
import API from '../../../../api';
import moment from 'moment';
import PaymentMethod from '../payment';
import ReactMarkdown from 'react-markdown/with-html';
import warning from '../../../../../images/warning.png';
import default_room from '../../../../../images/default-image.png';
import { isMobile } from 'react-device-detect';

const { Meta } = Card;
const { Panel } = Collapse;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

const locaData = [
  { kgEmpty: "-" }
]

const STANDARD_HTML_ENTITIES = {
    nbsp: String.fromCharCode(160),
    amp: "&",
    quot: '"',
    lt: "<",
    gt: ">"
};

const replaceHtmlEntities = plainTextString => {
  if (plainTextString != undefined) {
    return plainTextString
    .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
    .replace(
        /&(nbsp|amp|quot|lt|gt);/g,
        (a, b) => STANDARD_HTML_ENTITIES[b]
    )
    .replace(
        /(nbsp|amp|quot|lt|gt);/g,
        (a, b) => STANDARD_HTML_ENTITIES[b]
    );
  }
   else {
    return '';
   } 
};

class LeftPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      type: '',
      select_text: [],
      payment_type: '',
      payment_method: null,
      selectedValue: '',
      booking_confirmable: false,
      cancel_policy: '',
      hotel_norms: '',
      cancel_deadline: 'N/A',
      default_policy: '',
      supplements: '',
      meal_inclusion: '',
      total_price: '',
      detail: { rooms: [] },
      travellers: [],
      address_info: {},
      avail_token: '',
      hotel_address: '',
      check_in: '',
      check_out: '',
      request_code: '',
      loading: true,
      check_out_disabled: false,
      banklist: [],
      pbb_banklist: [],
      offline_banklist: '',
      fpxb2b_banklist: [],
      b2b_offline_banklist: '',
      banklist_select: [],
      credits: 0,
      method_visible: false,
      room_index: [],
      hotel_rooms: [],
      selected_rooms: [],
      selected_rooms_index: '',
      isRoomSelected: false,
      session_id: '',
      result_index: '',
      total_cost: '',
      currency: '',
      validationErrors: [],
      agree: '',
      bookingErrorModal: false,
      bookingErrorMessage: '',
      cancellationPolicyLoading: false,
      cancellationPolicyModal: false,
      remark: '',
      fpx_disabled: false,
      master_disabled: false,
      available_for_confirm_book: true,
      sale_rep: '',
      bank_type: null,
      redirect_url: null,
      dobw_banklist: [],
    }
    this.submitForm = this.submitForm.bind(this);
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
    // console.log(countryList().getData(), 'countryList')
  }

  /***************************************************** componentDidMount *******************************************************/
  componentDidMount() {
    this.banklistFetch();
  }

  /***************************************************** componentDidUpdate *******************************************************/
  componentDidUpdate() {
    if (this.props.dataList != this.state.detail) {
      var address_info = [];
      var travellers = [];
      var select_text = [];
      var validationErrors = [];
      if (this.props.dataList.hasOwnProperty('guest_count') && this.props.dataList.guest_count.length > 0) {
        for (var i = 0; i < this.props.dataList.guest_count.length; i++) {
          var guest = this.props.dataList.guest_count;
          var guest_room = this.props.dataList.guest_in_room;
          var ages = this.props.dataList.ages;
          var guest_index = travellers.filter(x => x.guest_in_room === guest_room[i]);
          travellers.push({ lead_guest: i == 0 ? "true" : "false", guest_type: guest[i], title: '', first_name: '', last_name: '', age: ages[i], guest_in_room: guest_room[i], guest_index: guest_index.length === undefined ? 1 : guest_index.length + 1 })
        }
      }
      address_info.push({ address_line_1: '', address_line_2: '', city: '', state: '', zip_code: '', country: '', country_code: '', area_code: '', phone_no: '', email: '' })
      validationErrors.push({ payment_type: '', payment_method: null, agree: '' })

      this.setState({
        detail: this.props.dataList,
        travellers: travellers,
        address_info: address_info,
        hotel_address: this.props.dataList.address,
        check_in: this.props.dataList.check_in,
        check_out: this.props.dataList.check_out,
        session_id: this.props.dataList.session_id,
        result_index: this.props.dataList.result_index,
        loading: false,
        validationErrors: validationErrors,
      })
    }
  }

  /***************************************************** banklistFetch api function *******************************************************/
  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          fpxb2b_banklist: response.data.fpxb2b_banklist,
          b2b_offline_banklist: response.data.b2b_offline_banklist,
          dobw_banklist: response.data.dobw_banklist,
          // credits: response.data.credits
        })
        that.props.getCredits(response.data.credits, response.data.credit_transaction_disabled)
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  /***************************************************** policyFetch api function *******************************************************/
  policyFetch() {
    const { room_index, session_id, result_index, selected_rooms } = this.state;

    let outputJson = {
      room_index: room_index,
      session_id: session_id,
      result_index: result_index,
      selected_rooms: selected_rooms
    };

    let url = './api/v1/hotels/' + session_id + '/room_policy/?' + qs.stringify(outputJson);
    var that = this;
    // console.log(qs.stringify(outputJson));

    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res policy');
        that.setState({
          booking_confirmable: response.data.cancellation_policies_available,
          cancel_policy: response.data.room_policy,
          hotel_norms: response.data.hotel_norms,
          cancel_deadline: response.data.cancel_deadline,
          default_policy: response.data.default_policy,
          supplements: response.data.supplements,
          meal_inclusion: response.data.meal_inclusion,
          hotel_rooms: response.data.hotel_rooms,
          fpx_disabled: response.data.booking_confirmable == true ? false : true,
          master_disabled: response.data.booking_confirmable == true ? false : true,
          available_for_confirm_book: response.data.booking_confirmable,
          cancellationPolicyLoading: true
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  /***************************************************** priceFormat function *******************************************************/
  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  /***************************************************** handleTravellerDataChange function *******************************************************/
  handleTravellerDataChange(index, type, value) {
    this.state.travellers[index][type] = value;
    if (type == "sale_rep") {
      this.setState({
        sale_rep: value
      });
    }
    this.setState({
      travellers: this.state.travellers
    })
  }

  /***************************************************** handleAddressInfoChange function *******************************************************/
  handleAddressInfoChange(type, value) {
    this.state.address_info[0][type] = value;
    this.setState({
      address_info: this.state.address_info
    })
  }

  handleRemarkChange(value) {
    this.setState({
      remark: value
    })
  }

  /***************************************************** handleSelectRoom function *******************************************************/
  handleSelectRoom(index, item) {
    if (this.state.selected_rooms_index !== index) {
      for (var i = 0; i < this.state.select_text.length; i++) {
        if (index === i) {
          this.state.select_text[index] = "SELECTED";
        }
        else {
          this.state.select_text[i] = "SELECT";
        }
      }

      this.state.room_index = item.room_index;
      this.state.selected_rooms = item.selected_rooms;

      this.setState({
        total_price: item.price + item.dta,
        total_cost: item.total_cost,
        currency: item.currency,
        room_index: this.state.room_index,
        selected_rooms: this.state.selected_rooms,
        selected_rooms_index: index,
      });

      this.policyFetch();
      // this.props.handleTotalPrice(item.price);
      this.props.handleSelectedRoom(item);
      console.log("Room Selected!")
    } else {
      console.log("Room Already Selected!")
    }
  }

  /***************************************************** hide function *******************************************************/
  hide = () => {
    this.setState({
      visible: false,
    });
  }

  handleCancellationPolicy = (status, item) => {
    if (status) {
      console.log("check cancellation policy handle,", item)

      this.state.room_index = item.room_index;
      this.state.selected_rooms = item.selected_rooms;

      this.setState({
        room_index: this.state.room_index,
        selected_rooms: this.state.selected_rooms,
        cancellationPolicyModal: status
      })
      this.policyFetch();
    } else {
      this.setState({
        cancellationPolicyModal: status
      }, () => { this.state.cancellationPolicyLoading = false })
    }
  };

  /***************************************************** handlePaymentChange function *******************************************************/
  handlePaymentChange(event) {
    console.log(event.target.value);
    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2c") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2b") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.fpxb2b_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "dobw") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.dobw_banklist,
        payment_method: null
      })
    }
    else {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null
      })
    }

    this.setState({
      payment_type: event.target.value
    })
    this.props.handlePaymentType(event.target.value);
  }

  /***************************************************** handlePaymentMethodChange function *******************************************************/
  handlePaymentMethodChange(value, option) {
    let payment_method = value;
    let bank_type = ""
    let redirect_url = ""

    if (this.state.payment_type === "dobw"){
      var found_bank = this.state.banklist_select.find((val) => val[1] === payment_method);
      redirect_url = found_bank[2]
      const splitString = payment_method.split(" - ");
      payment_method = splitString[0];
      bank_type = splitString[1];
    }

    this.setState({
      payment_method: payment_method,
      bank_type: bank_type,
      redirect_url: redirect_url
    })
    // console.log("card method",value)
  };

  /***************************************************** handleVisibleChange function *******************************************************/
  handleVisibleChange = visible => {
    this.setState({ visible });
  }

  /***************************************************** typeRadio function *******************************************************/
  typeRadio(event) {
    console.log(event.target.value);
    this.setState({
      type: event.target.value
    })
  }

  /***************************************************** onChangePaymentCheckBox function *******************************************************/
  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
  }

  onChangeInputNumber(value) {
    console.log('changed', value);
  }

  /***************************************************** openNotificationSelectRoom function *******************************************************/
  // openNotificationSelectRoom = type => {
  //   notification[type]({
  //     message: 'Warning!',
  //     description:
  //       'Please select your room.',
  //   });
  // };

  /***************************************************** openNotificationWithIcon function *******************************************************/
  openNotificationWithIcon = (type, error_name, message = '') => {
    if (type === "warning") {
      if (error_name === "payment_method_error") {
        notification[type]({
          message: 'Warning!',
          description:
            'Please select payment method before you continue.',
        });
      }
      if (error_name === "select_room_error") {
        notification[type]({
          message: 'Warning!',
          description:
            'Please select your room.',
        });
      }
    }
    if (type === "error") {
      if (error_name === "credits_error") {
        notification[type]({
          message: 'Error!',
          description:
            'Insufficient credits! please purchase more credits.',
        });
      }
      if (error_name === "booking_error") {
        notification[type]({
          message: 'Error!',
          description: message,
        });
      }
      if (error_name === "tbo_error") {
        notification[type]({
          message: 'Error!',
          description: message,
        });
      }
    }
  };

  /***************************************************** submitForm function *******************************************************/
  submitForm = e => {
    e.preventDefault();
    this.banklistFetch();

    const { travellers, payment_type, address_info, payment_method, supplements, meal_inclusion, hotel_rooms, cancel_policy,
      total_cost, currency, validationErrors, agree, remark, available_for_confirm_book, sale_rep, redirect_url, bank_type } = this.state;
    const data = this.props.dataList;
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let outputJson = {
      booking_form: {
        result_index: data.result_index,
        session_id: data.session_id,
        hotel_code: data.hotel_code,
        hotel_name: data.name,
        hotel_address: data.address,
        hotel_description: data.description,
        adult_count: data.adult_count,
        check_in: moment(data.check_in).format("YYYY-MM-DD"),
        check_out: moment(data.check_out).format("YYYY-MM-DD"),
        child_count: data.child_count,
        city_id: data.city_id,
        guest_nationality: params['search_criteria'] != undefined ? params['search_criteria']['country'] : 'MY', //add nationality from search
        total_cost: total_cost,
        currency: currency,
        sale_rep: sale_rep,
        no_of_rooms: data.room_booking_count,
        cancellation_policies: cancel_policy,
        hotel_rooms_attributes: hotel_rooms,
        remark: remark,
        available_for_confirm_book: available_for_confirm_book,
        guests_attributes: travellers,
        address_info_attributes: {
          address_line_1: address_info[0]['address_line_1'],
          address_line_2: address_info[0]['address_line_2'],
          city: address_info[0]['city'],
          state: address_info[0]['state'],
          zip_code: address_info[0]['zip_code'],
          country: address_info[0]['country'],
          country_code: address_info[0]['country_code'],
          area_code: address_info[0]['area_code'],
          phone_no: address_info[0]['phone_no'],
          email: address_info[0]['email']
        }
      },
      payment: { gateway: payment_type, method: payment_method, bank_type: bank_type, redirect_url: redirect_url }
    };
    console.log("This is the outputJson", outputJson);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (payment_type === "" || (payment_type !== "credit_transaction" ? payment_method === null : "") || agree === "" || total_cost === "" || !err === false) {
        if (!err) {
          console.log('Received values of form: ', values);
        }
        if (total_cost === "") {
          this.openNotificationWithIcon('warning','select_room_error');
        }
        if (payment_type === "") {
          this.openNotificationWithIcon('warning','payment_method_error');
        }
        if (payment_method === null) {
          validationErrors.payment_method = "*Please select your payment method";
          this.setState({ validationErrors: validationErrors })
        } else {
          validationErrors.payment_method = null;
          this.setState({ validationErrors: validationErrors })
        }
        if (agree === "") {
          validationErrors.agree = "*Please agreed the Terms of Use and Privacy Statement";
          this.setState({ validationErrors: validationErrors })
        } else {
          validationErrors.agree = "";
          this.setState({ validationErrors: validationErrors })
        }
      } else {
        this.setState({
          check_out_disabled: true,
        })

        let url = './api/v1' + this.props.location.pathname + '/create_booking/';
        let that = this;

        API.post(url, outputJson)
          .then(function (response) {
            // console.log(response, '------------res');
            if (response.data.redirect_to) {
              window.location = response.data.redirect_to
            } else if (response.data.credits_error) {
              that.openNotificationWithIcon('error','credits_error');
              that.setState({ payment_type: '', check_out_disabled: false })
            } else {
              that.openNotificationWithIcon('error', 'booking_error', response.data.error);
              that.setState({
                check_out_disabled: false
              })
              console.log(response.data.error, '---------error')
            }
            that.setState({
              loading: false
            })
          })
          .catch(function (error) {
            console.log(error);
            that.openNotificationWithIcon('error', 'tbo_error', error);
            that.setState({
              visible: true,
            })
          })
          .then(function () {
            // always executed
          });
      }
    });
  }

  /***************************************************** sliders function *******************************************************/
  /* sliders() {
    // console.log(this.props, '------this.props-------');
    const dataList = this.props.dataList;
    if (dataList.hasOwnProperty('adult_count')) {
      var adult_count = dataList.adult_count;
    }
    else {
      var adult_count = 0;
    }

    if (dataList.hasOwnProperty('child_count')) {
      var child_count = dataList.child_count;
    }
    else {
      var child_count = 0;
    }

    if (dataList.hasOwnProperty('rooms') && dataList.rooms.length > 0) {
      const all_room = dataList.rooms;
      return all_room.map((item, index) => {
        const { visible, select_text } = this.state
        return (
          <div className="card_warp" key={item}>
            <input type="radio" id={index} name="select" value="room" onClick={(event) => this.handleSelectRoom(index, item)} />
            <label htmlFor={index}>
              <Card hoverable >
                <Tooltip title={item.room_type}>
                  <Meta title={item.room_type} 
                  description={[
                    <div className="meta_warp" key={index} >
                      <div className="details">
                        <p>
                          <FontAwesomeIcon icon='user-friends' />&nbsp;&nbsp;{adult_count} Adult(s), {child_count} Child(s)<br/>
                        </p>
                        {
                          // this.state.hotel_rooms.length > 0 ? (
                          //   this.state.hotel_rooms.map((room, i) =>                         
                          //     <p>
                          //       <FontAwesomeIcon icon='utensils' />&nbsp;&nbsp;{room.meal_type}<br/>
                          //     </p>
                          //   )
                          // ) : (
                          //   null
                          // )
                        }
                        <p>
                          <FontAwesomeIcon icon='utensils' />&nbsp;&nbsp;{item.meal_type}<br/>
                        </p>
                      </div>
                      <div className="include">
                        <p className="margin_sty">
                          <span className="left"><small>{item.inclusion}</small></span>
                          <span>&nbsp;</span>
                        </p>
                        <p className="margin_sty">
                          <span>&nbsp;</span>
                          <span className="right price">RM {this.priceFormat(item.price)}</span>
                        </p>
                        <p className="margin_sty">
                          <span>&nbsp;</span>
                          <span className="right"><small>{item.price_desc}</small></span>
                        </p>
                      </div>
                      <p className="read"><b><small>&nbsp;{item.promotion}</small></b></p>
                      <Button type="primary" className="btn_room" >{select_text[index]}</Button>
                      <div className="clear"></div>
                    </div>
                  ]} />
                </Tooltip>
                
              </Card>
            </label>
          </div>
        )
      });
    } else {
      return (null)
    }
  } */

  render() {
    /********************************* state ******************************/
    const { type, travellers, address_info, booking_confirmable, cancel_policy, hotel_norms, cancel_deadline, default_policy,
      select_text, banklist_select, method_visible, payment_type, payment_method, offline_banklist, credits, validationErrors, supplements, meal_inclusion, bookingErrorModal, bookingErrorMessage, remark, selected_rooms_index, sale_rep, bank_type } = this.state;
    const { getFieldDecorator } = this.props.form;
    const data = this.props.dataList;
    const hotel_params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    // console.log(travellers, '------travellers')
    // console.log(supplements, '------supplements')
    // console.log(remark, '------remark')
    console.log("dataaaaa", data)

    if (data.hasOwnProperty('adult_count')) {
      var adult_count = data.adult_count;
    }
    else {
      var adult_count = 0;
    }

    if (data.hasOwnProperty('child_count')) {
      var child_count = data.child_count;
    }
    else {
      var child_count = 0;
    }

    /********************************* formItemLayout ******************************/
    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };


    if (data.hasOwnProperty('rooms') && data.rooms.length > 0) {
      for (var i = 0; i < data.rooms.length; i++) {
        select_text.push("SELECT");
      }
    }

    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
          }
        },
      ]
    };

    const cancellation_policy_columns = [
      {
        title: 'From Date',
        dataIndex: 'price'
      },
      {
        title: 'To Date',
        dataIndex: 'price'
      },
      {
        title: 'Charge',
        dataIndex: 'price'
      }
    ];

    const room_columns = [
      {
        // fixed: 'left',
        render: (record) => 
          <span>
            <Tooltip title={record.room_type}>
              <span><b>{record.room_type}</b></span>
            </Tooltip>
            <br />
            <span>
              <small>
                <FontAwesomeIcon icon='utensils' />&nbsp;&nbsp;{record.meal_type == "Room Only" ? "No Meal" : record.meal_type}
              </small>
            </span>
          </span>,
        width: isMobile ? "50%" : "60%"
      },
      {
        render: (record) => 
          <span>
            <span><b>RM {this.priceFormat(record.price + record.dta)}</b></span>
            <br />
            <span><small>{record.price_desc}</small></span>
            <br />
            <a type="link" onClick={() => { this.handleCancellationPolicy(true, record) }}><small>Cancellation Policy</small></a>
            <br />
            <span>
              {
                record.promotion ? 
                 (
                  <Tooltip title={record.promotion}>
                    <b><small>{record.promotion}</small></b>
                  </Tooltip>
                 )
                : (null)
              }
            </span>
          </span>,
        width: isMobile ? "50%" : "40%"
      }
    ];

    return (
      <div className="LeftPanel">
        {
          this.props.loading ? (
            <Skeleton active />
          ) : (
              <div className="hotel_details">
                {/**************************** header title ****************************/}
                <div className="header_title">
                  <h1>{data.name}</h1>
                  <Rate disabled defaultValue={Number(hotel_params['rating'])} />
                  <p className="location"><FontAwesomeIcon icon='map-marker-alt' />&nbsp;{data.address}</p>
                </div>

                {/**************************** Room Style ****************************/}
                {
                  data.hasOwnProperty('rooms') && data.rooms.length > 0 ?
                  (
                    <React.Fragment>
                      <div className="header_title">
                        <h2>Rooms</h2>
                      </div>

                      <div className="slider">
                        <Table 
                          // rowSelection={{
                          //   type: 'radio',
                          //   onChange: (index, item) => { this.handleSelectRoom(index, item[0]) },
                          // }}
                          onRow={(record, rowIndex) => {
                            return {
                              onClick: event => { this.handleSelectRoom(rowIndex, record) }
                            }
                          }}
                          rowClassName={ (record, index) => index === selected_rooms_index ? "room-row-selected" : '' }
                          showHeader={false}
                          align="left"
                          // scroll={{ x: 'max-content' }}
                          pagination={true}
                          pageSize={1}
                          columns={room_columns}
                          dataSource={data.rooms}
                        />
                      </div>
                    </React.Fragment>
                  ) : (null)
                }

                <Modal
                  title="Cancellation Policy"
                  visible={this.state.cancellationPolicyModal}
                  onCancel={() => { this.handleCancellationPolicy(false) }}
                  footer={<Button onClick={() => { this.handleCancellationPolicy(false) }}>Close</Button>}
                >
                  {
                    this.state.cancellationPolicyLoading ?
                    (
                      <React.Fragment>
                        <div>
                          <p><b>Last Cancellation Deadline: {cancel_deadline}</b></p>
                        </div>
                        <ReactMarkdown source={replaceHtmlEntities(cancel_policy)} escapeHtml={false} />
                        <ReactMarkdown source={replaceHtmlEntities(hotel_norms)} escapeHtml={false} />
                        <ReactMarkdown source={replaceHtmlEntities(supplements)} escapeHtml={false} />
                        <ReactMarkdown source={replaceHtmlEntities(meal_inclusion)} escapeHtml={false} />
                        <ReactMarkdown source={replaceHtmlEntities(default_policy)} escapeHtml={false} />
                      </React.Fragment>
                    ) : (
                      <Skeleton active />
                    )
                  }
                </Modal>

                {/*<Collapse defaultActiveKey={['1']} expandIconPosition='right' className="things">
                  <Panel header="Room Cancellation Policy" key="1" >
                    <div>
                      <p><b>Last Cancellation Deadline: {cancel_deadline}</b></p>
                    </div>
                    <ReactMarkdown source={cancel_policy} escapeHtml={false} />
                    <ReactMarkdown source={hotel_norms} escapeHtml={false} /><br/>
                    <ReactMarkdown source={supplements} escapeHtml={false} />
                    <ReactMarkdown source={meal_inclusion} escapeHtml={false} />
                    <ReactMarkdown source={default_policy} escapeHtml={false} />
                  </Panel>
                </Collapse> */}

                <Card className="things">
                  <h3 className="title"></h3>
                  <ReactMarkdown source={data.description} escapeHtml={false} />
                </Card>

                {/**************************** Attraction ****************************/}
                {
                  (data.attraction !== "") ?
                    <Card className="amenities">
                      <p className="title">Attractions</p>
                      <ReactMarkdown source={data.attraction} escapeHtml={false} />
                    </Card>
                  : ""
                }
                <br/>

                {/**************************** Facilities ****************************/}
                {
                  (data.facilities.length > 0) ? 
                    <Card className="amenities">
                      <p className="title">Facilities</p>
                      <ul className="icon_warp">
                        {
                          data.facilities.map((item, index) => {
                            return (
                              <li key={index}>{item.facility}</li>
                            )
                          })
                        }
                        <div className="clear"></div>
                      </ul>
                    </Card>
                  : ""
                }

                {/************************************************ Form ************************************************/}
                <Form {...formItemLayout} onSubmit={this.submitForm} className="form_sty_select" >

                  {/************************************ Guest Details ************************************/}
                  <div className="header_title">
                    <h2>Guest Details</h2>
                  </div>
                  <Card className="guest">
                    {travellers.map((traveller, index) => {
                      return (
                        <div key={index + 1}>
                          <div className="guest_content">
                            {
                              index == 0 || traveller.guest_in_room !== travellers[index - 1].guest_in_room ? 
                                (<div><h3>Room {traveller.guest_in_room}</h3><hr/></div>)
                              : ""
                            }
                            <h4>Guest {traveller.guest_index} : {traveller.guest_type}</h4>
                          </div>

                          <div className="form_guest">

                            <div className="input_warp">
                              {/************************************** Designation **************************************/}
                              <div className="form_control pandding_right">
                                <Form.Item label="Designation" >
                                  {getFieldDecorator('designation' + index, {
                                    rules: [{ required: true, message: '*Please select the designation', }],
                                  })(
                                    <Select showSearch placeholder="Select" onChange={(value) => this.handleTravellerDataChange(index, 'title', value)} >
                                      {
                                        traveller.guest_type == "Adult" ? 
                                          (
                                            ['Mr', 'Mrs', 'Ms'].map((designation) => {
                                              return (
                                                <Option key={designation + index} value={designation}>{designation}</Option>
                                              )
                                            })
                                          ) : (
                                            ['Mr', 'Ms'].map((designation) => {
                                              return (
                                                <Option key={designation + index} value={designation}>{designation}</Option>
                                              )
                                            })
                                          )
                                      }
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>
                              {/***************************************** First & middle name **********************************/}
                              <div className="form_control pandding_left ">
                                <Form.Item label="First Name (As per passport)" >
                                  {getFieldDecorator('first_name' + index, {
                                    rules: [{ required: true, pattern: new RegExp("^([A-Za-z]{2,}[\\s]?)+$"), message: '*Please fill in the name', min: 2 }],
                                  })(
                                    <Input placeholder="First Name" onChange={(event) => this.handleTravellerDataChange(index, 'first_name', event.target.value)} />
                                  )}
                                </Form.Item>
                              </div>
                            </div>

                            <div className="input_warp">
                              {/****************************************** Surname **********************************************/}
                              <div className="form_control pandding_right">
                                <Form.Item label="Last Name (As per passport)" >
                                  {getFieldDecorator('last_name' + index, {
                                    rules: [{ required: true, pattern: new RegExp("^([A-Za-z]{2,}[\\s]?)+$"), message: '*Please fill in the surname', }],
                                  })(
                                    <Input placeholder="Last Name" onChange={(event) => this.handleTravellerDataChange(index, 'last_name', event.target.value)} />
                                  )}
                                </Form.Item>
                              </div>

                              {/*********************************************** Age **************************************************/}
                              <div className="form_control pandding_left">
                                <Form.Item label="Age" >
                                  {getFieldDecorator('age' + index, {
                                    initialValue: traveller.age,
                                    rules: [
                                      // { min: 1, max: traveller.guest_type == "Adult" ? '' : 18, message: 'Invalid Age' },
                                      { required: true, message: '*Please fill in the age', }
                                    ],
                                  })(
                                    traveller.guest_type == "Adult" ? 
                                      <InputNumber min={1} max={100} placeholder="Age" onChange={(value) => this.handleTravellerDataChange(index, 'age', value)} />
                                    : <InputNumber min={1} max={18} placeholder="Age" disabled={true} />
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                            <div className="clear"></div>
                          </div>
                          <br />
                        </div>
                      )
                    })
                    }
                  </Card>

                  {/******************************************* Address Info *******************************************/}
                  <div className="header_title">
                    <h2>Additional Info</h2>
                  </div>
                  <Card className="guest">
                    <div className="form_guest">
                      <div className="input_warp">
                        {/********************************** Address Line 1 **********************************/}
                        {/*<div className="form_control pandding_right">
                          <Form.Item label="Address Line 1" >
                            {getFieldDecorator('address_1', {
                              // rules: [{ required: true, message: '*Please fill in the address line 1', }],
                            })(
                              <Input placeholder="Address Line 1" onChange={(event) => this.handleAddressInfoChange('address_line_1', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>*/}

                        {/************************************ Address Line 2 **********************************/}
                        {/*<div className="form_control pandding_left valierror_margin">
                          <Form.Item label="Address Line 2" >
                            {getFieldDecorator('address_2', {
                              // rules: [{ required: true, message: '*Please fill in the address line 2', }],
                            })(
                              <Input placeholder="Address Line 2" onChange={(event) => this.handleAddressInfoChange('address_line_2', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>*/}
                      </div>

                      <div className="input_warp">
                        {/****************************************** City ***************************************/}
                        {/*<div className="form_control pandding_right">
                          <Form.Item label="City" >
                            {getFieldDecorator('city', {
                              // rules: [{ required: true, message: '*Please fill in the city', }],
                            })(
                              <Input placeholder="City" onChange={(event) => this.handleAddressInfoChange('city', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>*/}

                        {/************************************* State ********************************************/}
                        {/*<div className="form_control pandding_left valierror_margin">
                          <Form.Item label="State" >
                            {getFieldDecorator('state', {
                              // rules: [{ required: true, message: '*Please fill in the state', }],
                            })(
                              <Input placeholder="State" onChange={(event) => this.handleAddressInfoChange('state', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>*/}
                      </div>

                      <div className="input_warp">
                        {/**************************************** Zipcode ****************************************/}
                        {/*<div className="form_control pandding_right">
                          <Form.Item label="Zipcode" >
                            {getFieldDecorator('zipcode', {
                              // rules: [{ required: true, message: '*Please fill in the zipcode', }],
                            })(
                              <Input placeholder="Zipcode" onChange={(event) => this.handleAddressInfoChange('zip_code', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>*/}

                        {/***************************************** Country ****************************************/}
                        {/*<div className="form_control pandding_left valierror_margin">
                          <Form.Item label="Country" >
                            {getFieldDecorator('country', {
                              // rules: [{ required: true, message: '*Please select the country', }],
                            })(
                              <Select showSearch placeholder="Select" onChange={(value) => this.handleAddressInfoChange('country', value)}>
                                {
                                  countryList().getData().map((country) => {
                                    return (
                                      <Option key={country.value} value={country.label}>{country.label}</Option>
                                    )
                                  })
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </div>*/}
                      </div>

                      <div className="input_warp">
                        {/************************************* Country Code *****************************************/}
                        {/*<div className="form_control pandding_right">
                          <Form.Item label="Country Code" >
                            {getFieldDecorator('country_code', {
                              // rules: [{ required: true, message: '*Please select the country code', }],
                            })(
                              <Select showSearch placeholder="Select Country Code" onChange={(value) => this.handleAddressInfoChange('country_code', value)}>
                                {
                                  countryList().getData().map((country) => {
                                    return (
                                      <Option key={country.value} value={country.value}>{country.value}</Option>
                                    )
                                  })
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </div>*/}

                        {/****************************************** Area code ******************************************/}
                        {/*<div className="form_control pandding_left valierror_margin">
                          <Form.Item label="Area Code" >
                            {getFieldDecorator('area_code', {
                              // rules: [{ required: true, message: '*Please fill in the area code', }],
                            })(
                              <Input placeholder="Area code" onChange={(event) => this.handleAddressInfoChange('area_code', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>*/}
                      </div>

                      <div className="input_warp">
                        {/******************************************* Phone number ***************************************/}
                        <div className="form_control pandding_right">
                          <Form.Item label="Mobile No." >
                            {getFieldDecorator('mobile', {
                              rules: [{ required: true, message: '*Please fill in the mobile number', }],
                            })(
                              <Input placeholder="Mobile number" onChange={(event) => this.handleAddressInfoChange('phone_no', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>

                        {/******************************************** Email **********************************************/}
                        <div className="form_control pandding_left valierror_margin">
                          <Form.Item label="E-mail">
                            {getFieldDecorator('email', {
                              rules: [
                                { type: 'email', message: 'The input is not valid E-mail', },
                                { required: true, message: '*Please fill in the E-mail', },
                              ],
                            })(
                              <Input placeholder="E-mail" onChange={(event) => this.handleAddressInfoChange('email', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="input_warp">
                        {/******************************************* Remark ***************************************/}
                        <div className="form_control">
                          <Form.Item label="Special Request" >
                            {getFieldDecorator('remark', {
                              // rules: [{ required: true, message: '*Please fill in the remark', }],
                            })(
                              <Input placeholder="Special request" onChange={(event) => this.handleRemarkChange(event.target.value)} />
                            )}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="input_warp">
                        {/******************************************* Sales person ***************************************/}
                        <div className="form_control">
                          <Form.Item label="Sales person">
                            {getFieldDecorator('sale_rep', {
                              rules: [{ required: true, pattern: new RegExp("^[A-Za-z]{1}[A-Za-z0-9\\s\\-\\.]{2,}$"), message: '*Please fill in the sales person', }],
                            })(                            
                              <Input onChange={(event) => this.handleTravellerDataChange(0, 'sale_rep', event.target.value)} placeholder="Sales person" />
                            )}
                          </Form.Item>
                          
                        </div>
                      </div>
                      <div className="clear"></div>
                    </div>
                  </Card>

                  {/**************************** Payment Method ****************************/}
                  <div className="header_title">
                    <h2>Your Payment Method</h2>
                  </div>

                  <PaymentMethod handlePaymentChange={this.handlePaymentChange.bind(this)} master_disabled={this.state.master_disabled} no_credit_payment={true} credit_disabled={this.props.credit_disabled} fpx_disabled={this.state.fpx_disabled} payment_type={payment_type} payment_method={payment_method} credits={this.props.credits} method_visible={method_visible} banklist_select={banklist_select} validationErrors={validationErrors} service_fee={this.props.service_fee} final_price={this.props.final_price} offline_banklist={offline_banklist} handlePaymentMethodChange={(value, option) => this.handlePaymentMethodChange(value, option)} fpxb2b_disabled={!data.fpxb2b_available}
                    onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)} allow_fpxb2c_overlimit={true} dobw_disabled={!data.dobw_available} fpxb2c_disabled={!data.fpxb2c_available} bank_type={bank_type}
                  />

                  <div className="after_select">
                    <Button type="primary" htmlType="submit" disabled={this.state.check_out_disabled}>CHECK OUT</Button>
                  </div>


                </Form>
              </div>
            )
        }
        <Modal
          visible={bookingErrorModal}
          title={null}
          footer={null}
          closable={false}
          destroyOnClose={() => this.onCloseModal('bookingErrorModal')}
        >
          <Empty
            image={warning}
            imageStyle={{
              height: 100,
            }}
            description={
              <p className="popup_text">{bookingErrorMessage}</p>
            }
            className="popup_footer"
          >
            <Button type="primary" onClick={() => this.onCloseModal('bookingErrorModal')}>Close</Button>
          </Empty>
        </Modal>
      </div>
    );
  }
}

const HotelShowPage = Form.create({ name: 'hotel' })(LeftPanel);
export default withRouter(HotelShowPage);
