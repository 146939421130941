import React, { Component } from 'react';
import { Collapse, Icon, Card, Tag } from 'antd';
import './index.css'
import moment from 'moment';
import Humanize from 'humanize-string';

const { Panel } = Collapse;

class RightPanel extends Component {

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  render() {
    const fare_type_selected = this.props.fare_type_selected
    const fare_type = this.props.fare_type
    const sums = this.props.sums
    const dta = this.props.dta
    const infant_dta = this.props.infant_dta
    const visa_fee = this.props.visa_fee
    const subtract_others = this.props.subtract_others
    const insurance_rebate = this.props.insurance_rebate
    const infant_visa_fee = this.props.infant_visa_fee
    const infant_subtract_others = this.props.infant_subtract_others
    const infant_insurance_rebate = this.props.infant_insurance_rebate
    const subtraction = fare_type_selected.includes("customer") ? 0 : this.props.subtraction
    const infant_subtraction = fare_type_selected.includes("customer") ? 0 : this.props.infant_subtraction
    const pre_commisions_total = this.props.pre_commisions_total
    const compulsory_additional_fee = this.props.compulsory_additional_fee
    const addon_others = this.props.addon_others
    const addon = this.props.addon
    const deposit = this.props.deposit
    const single_supplement = this.props.single_supplement
    const pax_count = this.props.pax_count
    const infant_count = this.props.infant_count
    const total = this.props.total
    const grand_total = this.props.grand_total
    const service_fee = this.props.service_fee;

    const prices = this.props.prices
    const infant_prices = this.props.infant_prices
    const dataList = this.props.dataList
    const loading = this.props.loading
    const tourKey = this.props.tourKey
    const payment_type = this.props.payment_type

    if (payment_type == "public_bank" || payment_type == "public_bank_2") {
      var final_total = grand_total + service_fee
      var final_deposit = deposit + service_fee
    }
    else {
      var final_total = grand_total
      var final_deposit = deposit
    }

    return (
      <div className="RightPanel res_panel_warp">
        <Card title={[
          <div className="title" key={0}>
            <h2>Summary</h2>
          </div>]}>
          <div className="dates">
            <p className="date_title">Tour</p>
            <p className="date_select">{dataList.tours && dataList.tours[tourKey].caption}</p>
          </div>
          <div className="dates">
            <p className="date_title">Tour Code</p>
            <p className="date_select">
              {dataList.tours && dataList.tours[tourKey].code}&nbsp;&nbsp;
              {dataList.tours && dataList.tours[tourKey].departure_location === "" || dataList.tours && dataList.tours[tourKey].departure_location === null ? null : <Tag className="location_tag" color="blue">{dataList.tours && dataList.tours[tourKey].departure_location}</Tag>}
            </p>
          </div>
          <div className="dates">
            <p className="date_title">Departure Date</p>
            <p className="date_select">{dataList.tours && dataList.tours[tourKey].departure_date} - {dataList.tours && dataList.tours[tourKey].arrival_date}</p>
          </div>
         {/* <div className="type_description">
            <div className="rent">
              <p className="rent_title">Description</p>
              <p className="rent_select">{dataList.caption}</p>
            </div>

            <div className="clear"></div>
          </div>*/}
          <div className="select_list">
            <p className="note_label">
            <strong>Note:</strong> { loading ? '' : dataList.tours && moment(dataList.tours[tourKey].deposit_date,'DD-MM-YYYY').isAfter() ? 'Only deposit required for this booking' : 'Full payment will be collected upon confirmation' }
            </p>
            
            <p className="date_title">Fare Breakdown</p>
              { 
                prices && prices.map((price, i) => { 
                  return(
                    price['quantity'] > 0 ?
                      <div className="list_warp" key={i}>
                        {
                          price['quantity'] > 1 ?
                          <React.Fragment>
                            <div className="subtraction_collapse">
                                <Collapse bordered={false} expandIconPosition="left" expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />} >
                                  <Panel header={
                                    <div className="list_warp fare_info">
                                      <span>
                                        <span>{Humanize(price['type'].replace(/[0-9]/g, ''))}</span>
                                        <div className="break-line"></div>
                                        <span>{price['name'] === "child_with_bed" ? "Child with extra bed" : price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} x <strong>{price['quantity']}</strong></span>
                                      </span> 
                                      <span>{dataList.price_currency} {this.priceFormat(price['price'] * price['quantity'])}</span>
                                    </div>
                                  }>
                                    <div>
                                      <span>Price per pax</span>
                                      <span>{dataList.price_currency} {this.priceFormat(price['price'])}</span>
                                    </div>
                                  </Panel>
                              </Collapse>
                            </div>
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <span>
                              <span>{Humanize(price['type'].replace(/[0-9]/g, ''))}</span>
                              <div className="break-line"></div>
                              <span>{price['name'] === "child_with_bed" ? "Child with extra bed" : price['name'] === "single_supplement" ? "Single" : price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} x <strong>{price['quantity']}</strong></span>
                            </span>                        
                            <span>{dataList.price_currency} {this.priceFormat(price['price'] * price['quantity'])}</span>
                          </React.Fragment>
                        }
                        {/*<span>{price['name'] === "child_with_bed" ? "Child with extra bed" : price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} ({price['type'].charAt(0).toUpperCase() + price['type'].substr(1).toLowerCase().replace(/_/g, " ")}) x <strong>{price['quantity']}</strong></span>                        
                        <span>{dataList.price_currency} {this.priceFormat(price['price'] * price['quantity'])}</span>*/}
                      </div>
                      : ''
                    )
                })
              }

              { 
                infant_prices && infant_prices.map((price, i) => {
                  return(
                    price['quantity'] > 0 ?
                      <div className="list_warp" key={i}>
                        {
                          price['quantity'] > 1 ?
                          <React.Fragment>
                            <div className="subtraction_collapse">
                                <Collapse bordered={false} expandIconPosition="left" expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />} >
                                  <Panel header={
                                    <div className="list_warp fare_info">
                                      <span>
                                        <span>{Humanize(price['type'].replace(/[0-9]/g, ''))}</span>
                                        <div className="break-line"></div>
                                        <span>{price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} x <strong>{price['quantity']}</strong></span>
                                      </span> 
                                      <span>{price['price'] === 0 ? 'TBC' : dataList.price_currency + ' ' + (this.priceFormat(price['price'] * price['quantity']))}</span>
                                    </div>
                                  }>
                                    <div>
                                      <span>Price per pax</span>
                                      <span>{price['price'] === 0 ? 'TBC' : dataList.price_currency + ' ' + this.priceFormat(price['price'])}</span>
                                    </div>
                                  </Panel>
                              </Collapse>
                            </div>
                          </React.Fragment>
                          :
                          <React.Fragment>
                            <span>
                              <span>{Humanize(price['type'].replace(/[0-9]/g, ''))}</span>
                              <div className="break-line"></div>
                              <span>{price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} x <strong>{price['quantity']}</strong></span>
                            </span>                        
                            <span>{price['price'] === 0 ? 'TBC' : dataList.price_currency + (this.priceFormat(price['price'] * price['quantity']))}</span>
                          </React.Fragment>
                        }
                      </div>
                    : ''
                  )
                })
              }
            {/*{
              pax_count === 1 && single_supplement > 0 ?
              <div className="list_warp">
                <span>Single Supplement</span>
                <span>{dataList.price_currency} {this.priceFormat(single_supplement)}</span>
              </div>
              : ''
            }*/}
            {
              dataList.tours && dataList.tours[tourKey].insurance ?
              <div className="list_warp">
                <span>Travel Insurance</span>
                <span>{dataList.tours[tourKey].insurance.slice(0,4)}</span>
              </div> : ''
            }
            {
              addon > 0 ?
              <div className="list_warp">
                <div className="subtraction_collapse">
                  <Collapse bordered={false} expandIconPosition="left" expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />} >
                    <Panel header={<div className="list_warp fare_info"><span>Add on</span><span>+ {dataList.price_currency} {this.priceFormat(addon)}</span></div>}>
                        {
                          compulsory_additional_fee > 0  ?
                          <div>
                            <span>Compulsory add. fee x <strong>{pax_count}</strong></span>
                            <span>+ {dataList.price_currency} {this.priceFormat(compulsory_additional_fee)}</span>
                          </div> : ''
                        }
                        {
                          addon_others > 0  ?
                          <div>
                            <span>Addon others x <strong>{pax_count}</strong></span>
                            <span>+ {dataList.price_currency} {this.priceFormat(addon_others)}</span>
                          </div> : ''
                        }
                    </Panel>
                  </Collapse>
                </div>
              </div> : ''
            }

            {
              subtraction > 0 ?
              <div className="list_warp">
                <div className="subtraction_collapse">
                  <Collapse bordered={false} expandIconPosition="left" expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />} >
                    <Panel header={<div className="list_warp fare_info"><span>Subtraction</span><span>- {dataList.price_currency} {this.priceFormat(subtraction)}</span></div>}>
                        {
                          dta > 0  ?
                          <div>
                            <span>DTA x <strong>{pax_count}</strong></span>
                            <span>- {dataList.price_currency} {this.priceFormat(dta)}</span>
                          </div> : ''
                        }
                        {
                          visa_fee > 0  ?
                          <div>
                            <span>Visa fee x <strong>{pax_count}</strong></span>
                            <span>- {dataList.price_currency} {this.priceFormat(visa_fee)}</span>
                          </div> : ''
                        }
                        {
                          insurance_rebate > 0  ?
                          <div>
                            <span>Special rebate(s) x <strong>{pax_count}</strong></span>
                            <span>- {dataList.price_currency} {this.priceFormat(insurance_rebate)}</span>
                          </div> : ''
                        }
                        {
                          subtract_others > 0  ?
                          <div>
                            <span>Subtract others x <strong>{pax_count}</strong></span>
                            <span>- {dataList.price_currency} {this.priceFormat(subtract_others)}</span>
                          </div> : ''
                        }
                        {
                          pre_commisions_total > 0 && dataList.tours[tourKey].pre_commisions  ? 
                            dataList.tours && dataList.tours[tourKey].pre_commisions.map((pre_commision) => {
                              return(
                                <div>
                                  <span>{pre_commision.title} x <strong>{pax_count}</strong></span>
                                  <span>- {dataList.price_currency} {this.priceFormat(pre_commision.category == 'fixed' ? pre_commision.amount * pax_count : pre_commision.category == 'percentage' ? ((sums - dta) * pre_commision.amount * pax_count / 100) : 0)}</span>
                                </div>
                              )
                            }) : ''
                        }
                    </Panel>
                  </Collapse>
                </div>
              </div> : ''
            }

            {
              infant_subtraction > 0 ?
              <div className="list_warp">
                <div className="subtraction_collapse">
                  <Collapse bordered={false} expandIconPosition="left" expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />} >
                    <Panel header={<div className="list_warp fare_info"><span>Infant Subtraction</span><span>- {dataList.price_currency} {this.priceFormat(infant_subtraction)}</span></div>}>
                        
                        {
                          infant_visa_fee > 0  ?
                          <div>
                            <span>Visa fee x <strong>{infant_count}</strong></span>
                            <span>- {dataList.price_currency} {this.priceFormat(infant_visa_fee)}</span>
                          </div> : ''
                        }
                        {
                          infant_insurance_rebate > 0  ?
                          <div>
                            <span>Special rebate(s) x <strong>{infant_count}</strong></span>
                            <span>- {dataList.price_currency} {this.priceFormat(infant_insurance_rebate)}</span>
                          </div> : ''
                        }
                        {
                          infant_subtract_others > 0  ?
                          <div>
                            <span>Subtract others x <strong>{infant_count}</strong></span>
                            <span>- {dataList.price_currency} {this.priceFormat(infant_subtract_others)}</span>
                          </div> : ''
                        }
                    </Panel>
                  </Collapse>
                </div>
              </div> : ''
            }

            {
              (payment_type == "public_bank" || payment_type == "public_bank_2") ?
                <div className="list_warp">
                  <span>Service Fees</span>
                  <span>{dataList.price_currency} {service_fee > 0 ? this.priceFormat(service_fee) : this.priceFormat(service_fee)}</span>
                </div>
              : ''
            }
          </div>

          {
            <div className="select_list">
              <div className="list_warp">
                <div className="total">
                  <span>Grand Total</span><span className="total_summary">{dataList.price_currency} {this.priceFormat(final_total)}</span>
                </div>
              </div>
            </div>
          }
          
          <div className="total">
            { 
              !this.props.dataList.auto_cancel ?
                <React.Fragment>
                  <p className="consider_sold">Once Booked Considered Sold.</p>
                </React.Fragment>
              : ''
            }
            {
              payment_type == 'pay_later' ?
                <React.Fragment>
                  {
                    !this.props.dataList.auto_cancel ?
                      <p className="pay_later">Your booking has been secured at the time of booking.</p> :
                      <p className="pay_later">Please note that once you have opted for the Book Now, Pay Later option, your booking will be secured up to {homepage_new.suka === "false" ? "72 hours" : "5 days"} from the time of booking.</p>
                  }
                  <p className="deposit_summary">Deposit needed latest by {homepage_new.suka === "false" ? moment().add(3, 'days').format('DD MMM YYYY') : moment().add(5, 'days').format('DD MMM YYYY')}: <strong>{dataList.price_currency} {this.priceFormat(final_deposit)}</strong></p> 
                  <p className="balance_note_label">Final payment of <strong>{dataList.price_currency} {this.priceFormat(final_total - final_deposit)}</strong> is due before <strong>{moment(dataList.tours && dataList.tours[tourKey].final_payment_date, 'DD/MM/YYYY').format('DD MMM YYYY')}</strong> ({dataList.tours && dataList.tours[tourKey].day_before_departure} days before departure. Subject to change)</p>
                </React.Fragment>
              : payment_type == 'credit_transaction' ?
                <React.Fragment>
                  <p className="deposit_summary">Deposit needed latest by {homepage_new.suka === "false" ? moment().add(3, 'days').format('DD MMM YYYY') : moment().add(5, 'days').format('DD MMM YYYY')}: <strong>{dataList.price_currency} {this.priceFormat(final_deposit)}</strong></p>
                  <p className="balance_note_label">Final payment of <strong>{dataList.price_currency} {this.priceFormat(final_total - final_deposit)}</strong> is due before <strong>{moment(dataList.tours && dataList.tours[tourKey].final_payment_date, 'DD/MM/YYYY').format('DD MMM YYYY')}</strong> ({dataList.tours && dataList.tours[tourKey].day_before_departure} days before departure. Subject to change)</p>
                </React.Fragment>
              : dataList.tours && moment(dataList.tours[tourKey].final_payment_date,'DD-MM-YYYY').isAfter() ?
                <React.Fragment>
                  {
                    (fare_type_selected == "" && fare_type == "deposit") || (fare_type_selected != '' && fare_type_selected.includes('deposit')) ? 
                      <div>
                        <p className="deposit_summary">Deposit due now: <strong>{dataList.price_currency} {this.priceFormat(final_deposit)}</strong></p>
                        <p className="balance_note_label">Final payment of <strong>{dataList.price_currency} {this.priceFormat(final_total - final_deposit)}</strong> is due before <strong>{moment(dataList.tours && dataList.tours[tourKey].final_payment_date, 'DD/MM/YYYY').format('DD MMM YYYY')}</strong> ({dataList.tours && dataList.tours[tourKey].day_before_departure} days before departure. Subject to change)</p>
                      </div>
                    : <p className="balance_note_label">Final payment of <strong>{dataList.price_currency} {this.priceFormat(final_total)}</strong> is due before <strong>{moment(dataList.tours && dataList.tours[tourKey].final_payment_date, 'DD/MM/YYYY').format('DD MMM YYYY')}</strong> ({dataList.tours && dataList.tours[tourKey].day_before_departure} days before departure. Subject to change)</p>
                  }
                </React.Fragment>
              : ''
            }
          </div>
          {/* <Button type="primary" id="check_out">CHECK OUT</Button>
            <Button type="primary" id="guest_detail">Guest Detail</Button> */}
        </Card>
      </div>
    );
  }
}

export default RightPanel;
