export const replaceHtmlEntities = (plainTextString) => {
    if (plainTextString !== undefined) {
      return plainTextString
        .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
        .replace(
          /&(nbsp|amp|quot|lt|gt);/g,
          (a, b) => STANDARD_HTML_ENTITIES[b]
        )
        .replace(
          /(nbsp|amp|quot|lt|gt);/g,
          (a, b) => STANDARD_HTML_ENTITIES[b]
        );
    } else {
      return '';
    }
};