import React, { Component } from 'react';
import { Drawer, Button, Spin, Affix, Result, Icon } from 'antd';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import { withRouter, Link } from 'react-router-dom';
import API from '../../../../api';
import './index.css';

class ThirdPartyOrderPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      return_url: null,
      error_msg: null,
      order_id: null,
      data: null,
      visible: false,
      loading: true,
      credits: 0,
      credit_disabled: true,
      credit_transaction_disabled: false,
      total_price: 0,
      payment_type: '',
      collect_type: '',
      top: 0,
    }
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  getCredits = (value, isCreditTransactionDisabled = false) => {
    this.setState({
      credits: value,
      credit_transaction_disabled: isCreditTransactionDisabled
    }, () => { this.handleUserCredits(); })
  };

  handleUserCredits = () => {
    if (this.state.credit_transaction_disabled) {
      return this.setState({
        credit_disabled: true
      })
    }

    if (this.state.credits && this.state.credits >= Number((this.state.total_price).toString().replace(/[^0-9\.-]+/g, ""))) {
      this.setState({
        credit_disabled: false
      })
    }
    else {
      this.setState({
        credit_disabled: true
      })
    }
  }

  componentDidMount() {
    console.log(this.props.location.pathname)
    let order_id = this.props.location.pathname.match(/\d+/);
    var that = this;
    var url = './api/v1/third_party/orders/'+order_id+'/payments/new';
    var params = this.props.location.search;

    API.get(url + params)
    .then(function (response) {
        console.log(response,'------------res');
        if (response.data.error == undefined) {
          that.setState({
            data: response.data,
            error_msg: null,
            return_url: null
          })
        }
        else {
          that.setState({
            data: null,
            error_msg: response.data.error,
            return_url: response.data.return_url
          })
        }

        that.setState({
          loading: false,
          order_id: order_id,
        })
    })
    .catch(function (error) {
        console.log(error);
    })
    .then(function () {
        // always executed
    });
  }

  handlePaymentType = (value) => {
    console.log(value, "--index payment_type")
    this.setState({
      payment_type: value
    });
  };

  render() {
    const { loading, data, credit_disabled, credits, payment_type, order_id, error_msg, return_url } = this.state;
    var service_fee = data !== null ? data.cc_rate * data.total_price : 0

    return (
      <div id="ThirdPartyOrderPayment">
        <div className="MenuContainer">
          {/******************************************** menu header **************************************************/}
          <div className="menu_header">
            <div className="title">
              <span>{homepage_new.app_name}</span> 
            </div>
          </div>
        </div>
        <Spin size="large" spinning={loading}>
          {
            data !== null ? (
              <div>
                <div className="warppage">
                  <LeftPanel loading={loading} data={data} getCredits={this.getCredits} credits={credits} credit_disabled={credit_disabled} handlePaymentType={this.handlePaymentType} service_fee={service_fee} order_id={order_id} />
                  <RightPanel loading={loading} data={data} service_fee={service_fee} payment_type={payment_type} />
                  <div className="clear"></div>
                </div>
                <div className="res_panel">
                  <Button type="primary" onClick={this.showDrawer} className="res_panel_btn">
                    Summary
                 </Button>
                  <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={this.onClose}
                    visible={this.state.visible}
                  >
                    <RightPanel loading={loading} data={data}/>
                  </Drawer>
                </div>
              </div>
            ) : (null)  
          }
          {
            error_msg !== null ? (
              <Result
                status={error_msg.includes("Paid") ? "success" : error_msg.includes("Pending") || error_msg.includes("Refunded") ? "warning" : "error"}
                title={error_msg}
                extra={return_url !== null ? (<Button type="primary" key="console" href={return_url}>Go Back</Button>) : ([])}
              >
              </Result>
            ) : (null)
          }
        </Spin>
      </div>
    );
  }
}

export default ThirdPartyOrderPayment;