import React, { Component } from 'react';
import {
  Form, Input, Card, Collapse, Tooltip, Icon, Cascader, DatePicker, InputNumber, Typography, Select, notification, Skeleton, Row,
  Col, Checkbox, Button, AutoComplete, Modal, Empty
} from 'antd';
import './index.css';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import countryList from 'react-select-country-list';
import qs from 'qs';
import API from '../../../../api';
import PaymentMethod from '../payment';
import warning from '../../../../../images/warning.png';
import ContactInfor from '../contactInfor';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Option } = Select;
const { OptGroup } = AutoComplete;
const { Paragraph } = Typography;
const dateFormat = 'YYYY-MM-DD';
const pickup_branch = [
  {
    name: 'Malaysia KLIA1',
    code: 'SEA10492'
  },
  {
    name: 'Malaysia KLIA2',
    code: 'SEA10582'
  },
  {
    name: 'Senai Airport',
    code: 'WHE00001'
  }
]

// function onCalendarChange(value) {
//   console.log("this is start date", moment(value[0]).format(dateFormat), "this is end date", moment(value[1]).format(dateFormat))
// }

class LeftPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      type: '',
      selectValue: '',
      payment_type: '',
      payment_method: null,
      // depart_date: moment().format(dateFormat),
      // return_date: '',
      endOpen: false,
      dateDisable: moment().startOf('day'),
      defDate: '',
      package_id: '',
      detail: {},
      contacts: [],
      loading: true,
      check_out_disabled: false,
      banklist: [],
      pbb_banklist: [],
      offline_banklist: '',
      fpxb2b_banklist: [],
      b2b_offline_banklist: '',
      banklist_select: [],
      // credits: 0,
      method_visible: false,
      validationErrors: [],
      agree: '',
      confirmDirty: false,
      autoCompleteResult: [],
      bookingErrorModal: false,
      bookingErrorMessage: '',
      sale_rep: '',
      bank_type: null,
      redirect_url: null,
      dobw_banklist: [],
    }
    this.submitForm = this.submitForm.bind(this);
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
  }

  /***************************************************** componentDidMount *******************************************************/
  componentDidMount() {
    this.banklistFetch();
  }

  /***************************************************** componentDidUpdate *******************************************************/
  componentDidUpdate() {
    if (this.props.dataList != this.state.detail) {
      // let quantityValue = this.state.quantity;
      var contacts = [];
      var validationErrors = [];
      contacts.push({
        name: '', mobile: '', email: '', remark: '', depart_date: moment().format(dateFormat), return_date: '', quantity: 1,
        ship_way: 'self_pickup', deliver_point_id: '', address: '', postcode: '', is_send_sms: 'yes', is_send_email: 'yes'
      })
      validationErrors.push({
        name: '', mobile: '', email: '', depart_date: '', return_date: '', quantity: 0, ship_way: '',
        deliver_point_id: '', address: '', postcode: '', agree: '',
      })
      this.setState({
        package_id: this.props.dataList.id,
        detail: this.props.dataList,
        contacts: contacts,
        validationErrors: validationErrors,
      }, () => { this.props.handleTotalQty(contacts[0].quantity) });
    }
  }

  /***************************************************** banklistFetch api *******************************************************/
  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    // console.log(params);
    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          fpxb2b_banklist: response.data.fpxb2b_banklist,
          b2b_offline_banklist: response.data.b2b_offline_banklist,
          dobw_banklist: response.data.dobw_banklist,
          // credits: response.data.credits
        })
        that.props.getCredits(response.data.credits, response.data.credit_transaction_disabled)
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  /***************************************************** handleContactDataChange function *******************************************************/
  handleContactDataChange(index, type, value, e = '') {
    this.state.contacts[index][type] = value;
    this.setState({
      contacts: this.state.contacts
    })

    // if (type === "depart_date") {
    //   this.setState({
    //     depart_date: moment(e).format(dateFormat),
    //     dateDisable: moment(e, dateFormat),
    //     // defDate: moment(e).format(dateFormat),
    //   }, () => { this.handleCalculateDays(); });
    //   this.props.handleDepartDate(e);
    // }
    if (type === "pickup_return_date") {
      this.state.contacts[index]['depart_date'] = moment(value[0]).format(dateFormat)
      this.state.contacts[index]['return_date'] = moment(value[1]).format(dateFormat)
      this.setState({
        contacts: this.state.contacts
      }, () => { this.handleCalculateDays(); });
      this.props.handleDepartDate(value[0]);
      this.props.handleReturnDate(value[1]);

    //   // console.log("From",moment(value[0]).format(dateFormat))
    //   // console.log("To", value[1])
    // }
    // else if (type === "return_date") {
    //   this.setState({ return_date: moment(e).format(dateFormat) }, () => { this.handleCalculateDays(); });
    //   this.props.handleReturnDate(e);
    }
    else if (type === "quantity") {
      console.log(value)
      this.props.handleTotalQty(value);
    }
    else if (type === "deliver_point_id") {
      console.log(value)
      this.props.handlePickUpLocation(pickup_branch.find(x => x.code === value).name);
    }
    else if (type == "sale_rep") {
      this.setState({
        sale_rep: value
      });
    }
  }

  /***************************************************** RangePicker handlers function *******************************************************/
  disabledDate = (current) => {
    if (this.state.dateDisable !== '') {
      return current && current < moment().startOf('day') || current && current.isSame(this.state.dateDisable, 'day')
    } else {
      return current && current < moment().startOf('day')
    }
  }

  handleRangePickerOpenChange = () => {
    this.setState({
      dateDisable: ''
    });
  }

  handleRangePickerChange = (value) => {
    this.setState({
      dateDisable: moment(value[0]).format(dateFormat)
    }, () => { this.disabledDate } );
  }

  /***************************************************** handleDepartOpenChange function *******************************************************/
  handleDepartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  /***************************************************** handleReturnOpenChange function *******************************************************/
  handleReturnOpenChange = open => {
    this.setState({ endOpen: open });
  };

  /***************************************************** handleCalculateDays function *******************************************************/
  handleCalculateDays = () => {
    var end = moment(this.state.contacts[0]['depart_date']);
    var now = moment(this.state.contacts[0]['return_date']);
    var duration = moment.duration(now.diff(end));
    var days = duration.asDays() + 1;
    this.props.handleTotalDay(days);
  }

  /***************************************************** openNotificationWithIcon function *******************************************************/
  openNotificationWithIcon = (type, error_name) => {
    if (type === "warning") {
      if (error_name === "payment_method_error") {
        notification[type]({
          message: 'Warning!',
          description:
            'Please select payment method before you continue.',
        });
      }
    }
    if (type === "error") {
      if (error_name === "credits_error") {
        notification[type]({
          message: 'Error!',
          description:
            'Insufficient credits! please purchase more credits.',
        });
      }
    }
  };

  /***************************************************** onChangePaymentCheckBox function *******************************************************/
  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
  }

  /***************************************************** onCloseModal function *******************************************************/
  onCloseModal = (modal) => {
    if (modal === 'bookingErrorModal') {
      this.props.history.go(-1);
    }
  };

  /***************************************************** hide function *******************************************************/
  hide = () => {
    this.setState({
      visible: false,
    });
  };

  /***************************************************** handlePaymentChange function *******************************************************/
  handlePaymentChange(event) {
    console.log(event.target.value);
    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2c") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2b") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.fpxb2b_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "dobw") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.dobw_banklist,
        payment_method: null
      })
    }
    else {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null
      })
    }
    this.setState({
      payment_type: event.target.value
    })
    this.props.handlePaymentType(event.target.value);
  }

  /************************************** handlePaymentMethodChange function *******************************************************/
  handlePaymentMethodChange(value, option) {
    let payment_method = value;
    let bank_type = ""
    let redirect_url = ""

    if (this.state.payment_type === "dobw"){
      var found_bank = this.state.banklist_select.find((val) => val[1] === payment_method);
      redirect_url = found_bank[2]
      const splitString = payment_method.split(" - ");
      payment_method = splitString[0];
      bank_type = splitString[1];
    }

    this.setState({
      payment_method: payment_method,
      bank_type: bank_type,
      redirect_url: redirect_url
    })
    // console.log("card method",value)
  };

  /******************************************** handleVisibleChange function *******************************************************/
  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  /***************************************************** typeRadio function *******************************************************/
  typeRadio(event) {
    console.log(event.target.value);
    this.setState({
      type: event.target.value
    })
  }

  /******************************************** handletripDataChange function *******************************************************/
  handletripDataChange(e) {
    this.setState({
      selectValue: e,
    })
  }

  /***************************************************** validateQuantity *******************************************************/
  validateQuantity = (rule, value, callback) => {
    const quantityData = this.state.contacts[0] || 0;
    const number = quantityData.quantity;
    console.log(number, '---------------------checkQuantity')
    if (number > 0) {
      callback();
      return;
    }
    callback('*Quantity must greater than zero');
  };

  /***************************************************** submitForm function *******************************************************/
  submitForm = e => {
    e.preventDefault();
    this.banklistFetch();

    const { contacts, payment_type, package_id, payment_method, validationErrors, agree, sale_rep, redirect_url, bank_type } = this.state;
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    // console.log(params, '---params')

    let outputJson = {
      id: package_id,
      sale_rep: sale_rep,
      roamingman_booking_group: {
        roamingman_bookings_attributes: contacts,
      },
      payment: { gateway: payment_type, method: payment_method, bank_type: bank_type, redirect_url: redirect_url },
      agree: agree,
    };
    console.log("This is the outputJson", outputJson);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (payment_type === "" || (payment_type !== "credit_transaction" ? payment_method === null : "") || agree === "" || !err === false) {
          if (!err) {
            console.log('Received values of form: ', values);
          }
          if (payment_type === "") {
            this.openNotificationWithIcon('warning','payment_method_error');
          }
          if (payment_method === null) {
            validationErrors.payment_method = "*Please select your payment method";
            this.setState({ validationErrors: validationErrors })
          } else {
            validationErrors.payment_method = null;
            this.setState({ validationErrors: validationErrors })
          }
          if (agree === "") {
            validationErrors.agree = "*Please agreed the Terms of Use and Privacy Statement";
            this.setState({ validationErrors: validationErrors })
          } else {
            validationErrors.agree = "";
            this.setState({ validationErrors: validationErrors })
          }
      }
      else {
        this.setState({
          check_out_disabled: true,
          // loading: true
        })

        let url = './api/v1' + this.props.location.pathname + '/create_booking/';
        let that = this;

        API.post(url, outputJson)
          .then(function (response) {
            console.log(response, '------------res');
            if (response.data.redirect_to) {
              window.location = response.data.redirect_to
            } else if (response.data.credits_error) {
              that.openNotificationWithIcon('error','credits_error');
              that.setState({ payment_type: '', check_out_disabled: false })
            } else {
              that.setState({
                check_out_disabled: false,
                bookingErrorModal: true,
                bookingErrorMessage: response.data.error
              })
              console.log(response.data.error)
            }
            that.setState({
              loading: false
            })
          })
          .catch(function (error) {
            console.log(error);
            that.setState({
              visible: true,
            })
          }).then(function () {
            // always executed
          });
        console.log(response.data.error)

      }
    });

  }


  render() {
    /********************************* state ******************************/
    const { type, banklist, offline_banklist, method_visible, banklist_select, /* credits, */ validationErrors, /* depart_date, */ defDate,
      /* return_date, */ endOpen, autoCompleteResult, bookingErrorModal, bookingErrorMessage, bank_type } = this.state;
    const { getFieldDecorator } = this.props.form;
    const data = this.props.dataList;
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const loading = this.props.loading;
    const contactsData = this.state.contacts[0] || 0;
    console.log(contactsData.depart_date, 'depart_date')
    console.log(contactsData.return_date, 'return_date')

    /********************************* formItemLayout ******************************/
    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };

    return (
      <div className="LeftPanel">
        <div className="hotel_details">
          {/************************************* header title *************************************/}
          <div className="header_title">
            <h1>{data.name} {data.category} Wifi</h1><br />
          </div>

          <Card className="things">
            <h3 className="title">Countries & Regions Covered</h3><br />
            <Paragraph ellipsis>
              {data.country}
            </Paragraph>
          </Card>

          {
            data.highlight === null || data.highlight === "" ? (
              null
            ) : (
                <Card className="things">
                  <h3 className="title">Highlight</h3><br />
                  <div dangerouslySetInnerHTML={{ __html: data.highlight }} />
                </Card>
              )
          }

          {
            data.tnc === null || data.tnc === "" ? (
              null
            ) : (
                <Card className="things">
                  <h3 className="title">Terms and Conditions</h3><br />
                  <div dangerouslySetInnerHTML={{ __html: data.tnc }} />
                </Card>
              )
          }

          {/***************************************************** Form *****************************************************/}
          <Form {...formItemLayout} onSubmit={this.submitForm} className="form_sty_select" >

            {/********************************** Device Info **********************************/}
            <div className="header_title">
              <h2>Device Info</h2>
            </div>
            {
              loading ? (
                <Skeleton active />
              ) : (
                  <Card title={[
                    <div className="passenger_content" key={0} >
                      <p>Device Details</p>
                    </div>
                  ]} className="passenger contact_infor"
                  >

                    <div className="form_passenger">
                      <div className="input_warp">
                        <div className="form_control pandding_right" >
                            <Form.Item label="Pick Up / Return Date" >
                              {getFieldDecorator('pickup_return_date', {
                                rules: [{ type: 'array', required: true, message: '*Please select the pick up / return date', }],
                              })(
                                <RangePicker
                                  placeholder={['Pick Up','Return']} 
                                  separator="-"
                                  allowClear={false}
                                  format={dateFormat}
                                  disabledDate={this.disabledDate}
                                  onOpenChange={() => this.handleRangePickerOpenChange()}
                                  onCalendarChange={(dateString) => this.handleRangePickerChange(dateString)}
                                  // onCalendarChange={onCalendarChange}
                                  onChange={(date, dateString) => this.handleContactDataChange(0, 'pickup_return_date', dateString, date)}
                                />
                              )}
                            </Form.Item>
                          </div> 
                        {/************************************** Depart Date **************************************/}
                        {/* <div className="form_control pandding_right" >
                          <Form.Item label="Depart Date" >
                            {getFieldDecorator('depart', {
                              initialValue: moment(depart_date),
                              rules: [{ required: true, message: '*Please select the depart date', }],
                            })(
                              <DatePicker
                                format={dateFormat}
                                disabledDate={current => {
                                  return (current && current < moment().startOf('day'))
                                }}
                                onChange={(date, dateString) => this.handleContactDataChange(0, 'depart_date', dateString, date)}
                                onOpenChange={this.handleDepartOpenChange}
                              />
                            )}
                          </Form.Item>
                        </div> */}

                        {/************************************** Return Date **************************************/}
                        {/* <div className="form_control pandding_left " >
                          <Form.Item label="Return Date">
                            {getFieldDecorator('return', {
                              initialValue: defDate,
                              rules: [{ required: true, message: '*Please select the return date', }],
                            })(
                              <DatePicker
                                dropdownClassName="return_date_datepicker"
                                format={dateFormat}
                                dateRender={current => {
                                  const style = {};
                                  if (current && current.isSame(moment(depart_date))) {
                                    style.border = '1px solid #1890ff';
                                    style.borderRadius = '50%';
                                    style.width = '70%';
                                  }
                                  return (
                                    <div className="ant-calendar-date" style={style}>
                                      {current.date()}
                                    </div>
                                  );
                                }}
                                disabledDate={current => {
                                  return (current && current < moment())
                                    || (depart_date && current < moment(depart_date).add(1, "day"))
                                }}
                                onChange={(date, dateString) => this.handleContactDataChange(0, 'return_date', dateString, date)}
                                open={endOpen}
                                defaultPickerValue={moment(depart_date).add(1, "day")}
                                onOpenChange={this.handleReturnOpenChange}
                              />
                            )}
                          </Form.Item>
                        </div>*/}

                          {/************************************** Quantity **************************************/}
                          <div className="form_control pandding_left Quantity_sty" >
                            <Form.Item label="Quantity" >
                              {getFieldDecorator('quantity', {
                                initialValue: contactsData.quantity,
                                rules: [{ required: true, validator: this.validateQuantity }],
                              })(
                                <InputNumber min={1} max={30} onChange={(value) => this.handleContactDataChange(0, 'quantity', value)} />
                              )}
                            </Form.Item>
                          </div>
                        </div> 

                        <div className="input_warp">
                          {/************************************** Pickup Location **************************************/}
                          <div className="form_control pandding_right" >
                            <Form.Item label="Pickup Location" >
                              {getFieldDecorator('pickup', {
                                initialValue: contactsData.deliver_point_id,
                                rules: [{ required: true, message: '*Please select the pickup branch', }],
                              })(
                                <Select showSearch placeholder="Select Location"
                                  onChange={(value) => this.handleContactDataChange(0, 'deliver_point_id', value)}
                                >
                                  {
                                    pickup_branch.map((item, i) => {
                                      return (
                                        <Option value={item.code} key={i}>{item.name}</Option>
                                      )
                                    })
                                  }
                                </Select>
                              )}
                            </Form.Item>
                          </div>

                          <div className="form_control pandding_left" >
                            <Form.Item label="Sales person" >
                              {getFieldDecorator('sale_rep', {
                                rules: [{ required: true, pattern: new RegExp("^[A-Za-z]{1}[A-Za-z0-9\\s\\-\\.]{2,}$"), message: '*Please fill in the sales person', }],
                              })(
                                <Input onChange={(event) => this.handleContactDataChange(0, 'sale_rep', event.target.value)} placeholder="Sales person" />
                              )}
                            </Form.Item>
                          </div>
                        </div>

                    </div>
                  </Card>
                )
            }

            {/************************************************* Contact Info Component ******************************************/}
            <ContactInfor loading={loading} getFieldDecorator={getFieldDecorator} contacts={this.state.contacts} />

            {/********************************************** Payment Method Component **********************************************/}
            <div className="header_title">
              <h2>Your Payment Method</h2>
            </div>
            <PaymentMethod handlePaymentChange={this.handlePaymentChange.bind(this)} no_credit_payment={this.props.username == 'ktic' || true} credit_disabled={this.props.credit_disabled} fpx_disabled={false} payment_type={this.state.payment_type} payment_method={this.state.payment_method} credits={this.props.credits} method_visible={method_visible} validationErrors={validationErrors} banklist_select={banklist_select} offline_banklist={offline_banklist} handlePaymentMethodChange={(value, option) => this.handlePaymentMethodChange(value, option)} onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)} terms={homepage_new.wifi_terms} service_fee={this.props.service_fee} final_price={this.props.final_price} fpxb2b_disabled={!data.fpxb2b_available} allow_fpxb2c_overlimit={false} dobw_disabled={!data.dobw_available} fpxb2c_disabled={!data.fpxb2c_available} bank_type={bank_type} />
            <div className="after_select">
              <Button type="primary" htmlType="submit" disabled={this.state.check_out_disabled}>CHECK OUT</Button>
            </div>

          </Form>
        </div>

        <Modal
          visible={bookingErrorModal}
          title={null}
          footer={null}
          closable={false}
          destroyOnClose={() => this.onCloseModal('bookingErrorModal')}
        >
          <Empty
            image={warning}
            imageStyle={{
              height: 100,
            }}
            description={
              <p className="popup_text">{bookingErrorMessage}</p>
            }
            className="popup_footer"
          >
            <Button type="primary" onClick={() => this.onCloseModal('bookingErrorModal')}>Close</Button>
          </Empty>
        </Modal>
      </div>
    );
  }
}
const WifiShowPage = Form.create({ name: 'wifi' })(LeftPanel);
export default withRouter(WifiShowPage);
