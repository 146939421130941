import React, { Component } from 'react';
import { Form, Card, Descriptions, Radio, Skeleton, Button, notification } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import API from '../../../../api';
import PaymentMethod from '../../show/payment';
import './index.css';

class LeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      method_visible: false,
      payment_method: null,
      payment_type: '',
      banklist: [],
      pbb_banklist: [],
      offline_banklist: '',
      banklist_select: [],
      agree: '',
      validationErrors: [],
      check_out_disabled: false,
      fpxb2b_banklist: [],
      b2b_offline_banklist: '',
      fare_type_selected: '',
      set_amount: 0,
      bank_type: null,
      redirect_url: null,
      dobw_banklist: [],
      user: null,
    }
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
  }

  priceFormat(value) {
    return value === undefined ? 0.00 : parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  /****************************************** NotificationDialogBox function *******************************************************/
  openNotificationWithIcon = (type, error_name) => {
    if (type === "warning") {
      if (error_name === "payment_method_error") {
        notification[type]({
          message: 'Warning!',
          description:
            'Please select payment method before you continue.',
        });
      }
    }
    if (type === "error") {
      if (error_name === "credits_error") {
        notification[type]({
          message: 'Error!',
          description:
            'Insufficient credits! please purchase more credits.',
        });
      }
    }
  };

  onChangeData = (index, type, value, e = '') => {
    if (type == "fare_type_selected") {
      this.setState({
        fare_type_selected: value,
        set_amount: 0
      }, () => { this.handleExtraDeposit(value) })
    }
    else if (type == "set_amount"){
      this.setState({
        set_amount: value
      }, () => { this.handleSetAmount(value) })
    }
  };

  /******************************************* handlePaymentChange function *******************************************************/
  handlePaymentChange(event) {
    console.log(event.target.value);

    if (event.target.value === 'credit_transaction' && this.state.user == null) {
      window.location = './users/sign_in#' + this.props.location.pathname;
    }

    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null,
        set_amount: 0
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null,
        set_amount: 0
      })
    }
    else if (event.target.value === "fpx_b2c") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null,
        set_amount: 0
      })
    }
    else if (event.target.value === "fpx_b2b") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.fpxb2b_banklist,
        payment_method: null,
        set_amount: 0
      })
    }
    else if (event.target.value === "dobw") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.dobw_banklist,
        payment_method: null,
        set_amount: 0
      })
    }
    else {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null,
        set_amount: 0
      })
    }

    this.props.handlePaymentType(event.target.value);
    this.setState({
      payment_type: event.target.value
    })
  }

  /************************************** handlePaymentMethodChange function *******************************************************/
  handlePaymentMethodChange(value, option) {
    let payment_method = value;
    let bank_type = ""
    let redirect_url = ""

    if (this.state.payment_type === "dobw"){
      var found_bank = this.state.banklist_select.find((val) => val[1] === payment_method);
      redirect_url = found_bank[2]
      const splitString = payment_method.split(" - ");
      payment_method = splitString[0];
      bank_type = splitString[1];
    }

    this.setState({
      payment_method: payment_method,
      bank_type: bank_type,
      redirect_url: redirect_url
    })
    // console.log("card method",value)
  };

  /*************************************** onChangePaymentCheckBox function *******************************************************/
  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
  }

  /***************************************************** banklistFetch *******************************************************/
  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          fpxb2b_banklist: response.data.fpxb2b_banklist,
          b2b_offline_banklist: response.data.b2b_offline_banklist,
          dobw_banklist: response.data.dobw_banklist,
          // credits: response.data.credits
        })
        that.props.getCredits(response.data.credits, response.data.credit_transaction_disabled)
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  /***************************************************** componentDidMount *******************************************************/
  componentDidMount() {
    this.userFetch();
    this.banklistFetch();
  };

  userFetch() {
    const that = this;

    API.get("./api/v1/users/details")
      .then(function (response) {
        console.log(response, "===user response")
        if (response.data.id != null) {
          that.setState({
            user: response.data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  /***************************************************** submitForm *******************************************************/
  submitForm = event => {
    event.preventDefault();
    this.banklistFetch();

    const { payment_type, payment_method, agree, validationErrors, fare_type_selected, set_amount, bank_type, redirect_url } = this.state;
    let allow_customer_pay = this.props.data && this.props.data.allow_customer_pay

    let outputJson = {
      order_id: this.props.order_id,
      payment: { gateway: payment_type, method: payment_method, bank_type: bank_type, redirect_url: redirect_url },
      agree: agree,
    };
    console.log("This is the outputJson", outputJson);

    this.props.form.validateFieldsAndScroll((err, values) => {

      if (payment_type === "" || ((payment_type !== 'credit_transaction' && payment_type !== 'pay_later') ? payment_method === null : '') || agree === "" || !err === false) {

        if (!err) {
          console.log('Received values of form: ', values);
        }

        if (payment_type === "") {
          this.openNotificationWithIcon('warning','payment_method_error');
        }
        if (payment_method === null) {
            validationErrors.payment_method = "*Please select a payment method";
            this.setState({ validationErrors: validationErrors })
        } else {
            validationErrors.payment_method = null;
            this.setState({ validationErrors: validationErrors })
        }

        if (agree === "") {
          validationErrors.agree = "*Please agreed the Terms of Use and Privacy Statement";
          this.setState({ validationErrors: validationErrors })
        } else {
          validationErrors.agree = "";
          this.setState({ validationErrors: validationErrors })
        }

        console.log("validationErrors", validationErrors);
      }
      else {
        var url = './api/v1/third_party/orders/'+this.props.order_id+'/payments';
        let that = this;
        let params = this.props.location.search;
        that.setState({
          check_out_disabled: true,
        })

        API.post(url + params, outputJson)
          .then(function (response) {
              console.log(response,'------------res');
              console.log(response.data.redirect_to,'------------redirect');
              if (response.data.redirect_to) {
                window.location = response.data.redirect_to
              } else if (response.data.credits_error) {
                that.openNotificationWithIcon('error','credits_error');
                that.setState({ payment_type: '', check_out_disabled: false })
              }
              else {
                that.setState({
                  check_out_disabled: true,
                })
              }
        })
        .catch(function (error) {
          console.log(error);
          that.setState({
            visible: true,
            check_out_disabled: false,
          })
        })
        .then(function () {
          // always executed
        });
      }
    });
  }

  render() {
    const { method_visible, offline_banklist, banklist_select, credits, payment_method, payment_type, validationErrors, check_out_disabled, bank_type } = this.state;
    const loading = this.props.loading;
    
    if (this.props.data != null) {
      var final_price = this.props.data.total_price
      var service_fee = this.props.service_fee
    }
    else {
      var final_price = 0
      var service_fee = 0
    }

    return (
      loading ? <Skeleton active /> :
        <div className="LeftPanel">
          <div className="header_title">
            <h2>Order: {this.props.data.service_name} - {this.props.data.order_id}</h2>
          </div>

          {/************************************************ Form ************************************************/}
          <Form onSubmit={this.submitForm} className="form_sty_select" >
            {/******************************* Payment table details  ********************************/}
            <Card >
              <Descriptions bordered size='default' layout="vertical">
                <Descriptions.Item label={<strong>Date</strong>}>{this.props.data.created_at}</Descriptions.Item>
                <Descriptions.Item label={<strong>Product Type</strong>} span={2}>{this.props.data.service_name}</Descriptions.Item>
                <Descriptions.Item label={<strong>Name</strong>} span={2}>{this.props.data.agent_name}</Descriptions.Item>
                <Descriptions.Item label={<strong>Price</strong>}>RM {this.priceFormat(this.props.data.total_price)}</Descriptions.Item>
                <Descriptions.Item label={<strong>Description</strong>} span={3}>{this.props.data.description}</Descriptions.Item>
                <Descriptions.Item label={<strong>Payment</strong>} span={3}>Collect Payment: RM {this.priceFormat(this.props.data.total_price)} </Descriptions.Item>
              </Descriptions>
            </Card>

            {/********************************************** Payment Method Component **********************************************/}
            <div className="payment_dedicated_warp ">
              <div className="header_title">
                <h2>Your Payment Method</h2>
              </div>
              <PaymentMethod paymentTypeAvailable={this.props.data.pay_later_available == true ? 4 : ''} handlePaymentChange={this.handlePaymentChange.bind(this)} no_credit_payment={false} credit_disabled={this.props.credit_disabled} fpx_disabled={false} payment_type={payment_type} payment_method={payment_method} credits={this.props.credits} method_visible={method_visible} validationErrors={validationErrors} banklist_select={banklist_select}
                offline_banklist={offline_banklist} handlePaymentMethodChange={(value, option) => this.handlePaymentMethodChange(value, option)} onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)} final_price={final_price} service_fee={service_fee} pageName={"ThirdPartyOrderPaymentPage"} fpxb2b_disabled={!this.props.data.fpxb2b_available} dobw_disabled={!this.props.data.dobw_available} fpxb2c_disabled={!this.props.data.fpxb2c_available} pbb2_disabled={!this.props.data.pbb2_available} dobw_cor_disabled={!this.props.data.dobw_cor_available} dobw_ret_disabled={!this.props.data.dobw_ret_available} credit_disabled={!this.props.data.credit_available} fare_type={"balance"}  onChangeData={this.onChangeData.bind(this)} allow_fpxb2c_overlimit={false} total_price={this.props.total_price} fare_type_selected={this.state.fare_type_selected} bank_type={bank_type} paylater_disabled={!this.props.data.pay_later_available}
              />
            </div>
            <div className="after_select">
              <Button type="primary" htmlType="submit" disabled={check_out_disabled}>CHECK OUT</Button>
            </div>
          </Form>
        </div>

    );
  }
}

const ThirdPartyOrderPaymentPage = Form.create({ name: 'third_party_payment' })(LeftPanel);
export default withRouter(ThirdPartyOrderPaymentPage);
