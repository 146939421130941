import React, { Component } from 'react';
import { Table, Tag, Typography, Col, Row, Statistic, Card } from 'antd';
import MenuBooking from '../menu';
import API from '../../../../api'
import './index.css';

const { Paragraph } = Typography;

const columns = [
  {
    title: 'Transaction Date',
    width: 100,
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'References',
    width: 200,
    dataIndex: 'references',
    key: 'references',
  },
  {
    title: 'Amount',
    width: 100,
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
  },
  {
    title: 'Remaining Balance',
    width: 150,
    dataIndex: 'remaining_balance',
    key: 'remaining_balance',
    align: 'right',
  }
];

class CreditUsage extends Component {
  state = {
    data: [],
    user: [],
    isLoading: true,
  };

  componentDidMount() {
    this.apiFetch();
    this.userFetch();
  }

  apiFetch(params = '') {
    let url = './api/v1/users/credit_usage';
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        that.setState({
          data: response.data,
        })

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
        that.setState({
          isLoading: false
        })
      });

  }

  userFetch() {
    const that = this;

    API.get('./api/v1/users/details')
      .then(function (response) {
        that.setState({
          user: response.data,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  render() {
    const { isLoading } = this.state

    return (
      <div id="CreditUsageContainer">
        <MenuBooking selected="credit_usage" />
        <div className="content">
          <p className="title">Credit Usage</p>
          <div className="credit_statistic">
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Credit Limit"
                    value={this.state.user.credit_limit}
                    precision={2}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Credit Balance"
                    value={this.state.user.credits}
                    precision={2}
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <br/>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={this.state.data}
            scroll={{ x: 'max-content' }}
            className="table_warp" />
        </div>
      </div>
    );
  }
}

export default CreditUsage;