import React, { Component } from 'react';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel'
import Lightbox from 'react-image-lightbox';
import './index.css';
import ShowMenu from '../menu';
import API from '../../../../api';
import { withRouter, Link,  } from 'react-router-dom';
import moment from 'moment';
import { Drawer, Button , Spin} from 'antd';
import Footer from '../../home/footer/index';

class ShowActivity extends Component {
    constructor(props) {
      super(props);
      this.state = {
        photoIndex: 0,
        isOpenSelectedPhotos: false,
        isOpenAllPhotos: false,
        visible: false,
        params: '',
        loading: true,
        credits: 0,
        credit_disabled: false,
        credit_transaction_disabled: false,
        dataList: [],
        ticketDetails: [],
        visitDate: '',
        payment_type: '',
        totalPrice: 0,
        service_fee: 0,
        final_price: 0,
        user: []
      }
      this.handleTicketQuantity = this.handleTicketQuantity.bind(this);
      this.handleVisitDate = this.handleVisitDate.bind(this);
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    componentDidMount() {
        this.userFetch();
        // this.banklistFetch();
        window.scrollTo(0, 0)

        // Change Moment.js "Invalid Date" message
        moment.updateLocale(moment.locale(), { invalidDate: '' });

        //console.log(getUrl, '-----------getUrl');
        const params = this.props.location.search;
        let url = './api/v1' + this.props.location.pathname;
        var that = this;
        // console.log(params);

        API.get(url + params)
            .then(function (response) {
                console.log(response,'------------res');

                that.setState({
                    dataList: response.data,
                    loading: false,
                    params: params
                })
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    // banklistFetch() {
    //     let url = './api/v1/payments/payment_method';
    //     var that = this;

    //     API.get(url)
    //         .then(function (response) {
    //             // console.log(response, '------------res banklist');
    //             that.setState({
    //                 credits: response.data.credits,
    //             })
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         })
    //         .then(function () {
    //             // always executed
    //         });
    // }

    getDataTickets = (value) => {
        this.setState({
            ticketDetails: value
        })
    };

    handleSelectPhoto = (e, selectedPhotoIndex) => {
      e.preventDefault();
      this.setState({ 
        isOpenSelectedPhotos: true, 
        photoIndex: selectedPhotoIndex
      })
    };

    getCredits = (value, isCreditTransactionDisabled = false) => {
        this.setState({
          credits: value,
          credit_transaction_disabled: isCreditTransactionDisabled
        }, () => { this.handleUserCredits(); })
    }
 
    handleUserCredits() {
      if (this.state.credit_transaction_disabled) {
        return this.setState({
          credit_disabled: true
        })
      }

      if (this.state.credits >= Number((this.state.totalPrice).toString().replace(/[^0-9\.-]+/g, ""))) {
        this.setState({
            credit_disabled: false
        })
      }
      else {
        this.setState({
            credit_disabled: true
        })
      }
    }

    handleTotalPrice(value) {
      var service_fee = this.state.dataList.cc_rate * value;

      this.setState({
          totalPrice: value,
          service_fee: service_fee
      }, () => { this.handleUserCredits(); })
    };

    handleTotalAmount() {
        // const ticketDetails = this.state.dataList.taggable_type == 'GT' ? this.state.ticketDetails : this.state.dataList.taggable_type == 'ACT' ? this.state.ticketDetails && this.state.ticketDetails.filter((t) => t.visible === true) : '';
        const ticketDetails = this.state.ticketDetails && this.state.ticketDetails.filter((t) => t.visible === true);
        let dta = 0;
        let total_price = 0;
        ticketDetails && ticketDetails.map((ticket, index) => {
          if (this.state.user.collect_dta) {
            dta += 0;
          }
          else {
            dta += ticket.dta * ticket.quantity;
          }
          total_price += ticket.quantity * ticket.price;
        })
        return total_price - dta;
    }

    handleTicketQuantity(index, type, value, e = '') {
        let ticketDetails = this.state.ticketDetails
        ticketDetails[index][type] = value
        this.setState({
            ticketDetails: ticketDetails
        })
        this.handleTotalPrice(this.handleTotalAmount())
    }

    handlePaymentType = (value) => {
      this.setState({
        payment_type: value
      })
    }

    handleVisitDate(value) {
        this.setState({
            visitDate: moment(value).format("DD/MM/YYYY"),
        })
        // console.log(moment(value).format("DD/MM/YYYY"))
        this.handleTotalPrice(this.handleTotalAmount())
    }

    checkImages() {
        if (this.state.dataList.taggable_type === "GT") {
            return this.state.dataList.images
        } else {
            if (this.state.dataList.images && this.state.dataList.images.length > 0) {
                return this.state.dataList.images[0].url
            } else {
                return homepage_new.default_image
            }
        }
    }

    userFetch() {
      const that = this;

      API.get('./api/v1/users/details')
        .then(function (response) {
          console.log(response, '------------res');
          that.setState({
            user: response.data,
          })
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }

    render() {
      const { credit_disabled, ticketDetails, totalPrice, credits, visitDate, params, dataList, loading, payment_type, service_fee } = this.state;
      // console.log("totalPrice========>", totalPrice)
      // console.log("service_fee========>", service_fee)

      return (
        <div>
          <ShowMenu />
          <div id="ShowActivity">
              
            <div>
              <div className="banner"></div>
              <div id="overlay"></div>

              <div className="logo_top">
                <Link to="/">
                  <div className="logo_warp">
                    <img src={homepage_new.app_logo} alt="ice" />
                    <span>TRAVELB2B</span>
                  </div>
                </Link>
              </div>

              <div className="img_lightbox">
                <div className="img_cantainer">
                  {
                    this.state.dataList.images && this.state.dataList.images[0] && (
                      <div className="img_warp">
                        <a href="#/" onClick={e => this.handleSelectPhoto(e, 0)}>
                          <img src={this.state.dataList.images[0]} />
                        </a>
                      </div>
                    )
                  }
                  {
                    this.state.dataList.images && this.state.dataList.images[1] && (
                      <div className="img_warp">
                        <a href="#/" onClick={e => this.handleSelectPhoto(e, 1)}>
                          <img src={this.state.dataList.images[1]} />
                        </a>
                      </div>
                    )
                  }
                  <div className="clear"></div>
                </div>
                {
                  this.state.dataList.images && this.state.dataList.images.length > 0 && (
                    <Button type="primary" onClick={() => this.setState({ isOpenAllPhotos: true })}>VIEW MORE</Button>
                  )
                }
                {this.state.isOpenAllPhotos && (
                  <Lightbox
                    mainSrc={this.state.dataList.images[this.state.photoIndex]}
                    nextSrc={this.state.dataList.images[(this.state.photoIndex + 1) % this.state.dataList.images.length]}
                    prevSrc={this.state.dataList.images[(this.state.photoIndex + this.state.dataList.images.length - 1) % this.state.dataList.images.length]}
                    onCloseRequest={() => this.setState({ isOpenAllPhotos: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (this.state.photoIndex + this.state.dataList.images.length - 1) % this.state.dataList.images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (this.state.photoIndex + 1) % this.state.dataList.images.length,
                      })
                    }
                  />
                )}
              </div>
            </div>

            <Spin size="large" spinning={loading}>
                <div className="warppage">
                    <LeftPanel dataList={dataList} loading={loading} getDataTickets={this.getDataTickets} getCredits={this.getCredits} handleVisitDate={this.handleVisitDate} handleTicketQuantity={this.handleTicketQuantity} credit_disabled={credit_disabled} visitDate={visitDate} totalPrice={totalPrice} credits={credits} handlePaymentType={this.handlePaymentType} payment_type={payment_type} service_fee={service_fee} final_price={totalPrice} />
                    <RightPanel params={params} dataList={dataList} loading={loading} visitDate={visitDate} ticketDetails={ticketDetails} collectDta={this.state.user.collect_dta} payment_type={payment_type} service_fee={service_fee} final_price={totalPrice} />
                    <div className="clear"></div>
                </div>
                <div className="res_panel">
                    <Button type="primary" onClick={this.showDrawer} className="res_panel_btn">
                        Summary
                 </Button>
                    <Drawer
                        title=""
                        placement="right"
                        closable={true}
                        onClose={this.onClose}
                        visible={this.state.visible}
                    >
                        <RightPanel params={params} dataList={dataList} loading={loading} visitDate={visitDate} ticketDetails={ticketDetails} collectDta={this.state.user.collect_dta} payment_type={payment_type} service_fee={service_fee} final_price={totalPrice} />
                    </Drawer>
                </div>
            </Spin>

          </div>
          <Footer />
        </div>
      );
    }
}

export default withRouter(ShowActivity);
