import React, { Component } from "react";
import { Drawer, Button, Spin, Modal } from "antd";
import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";
import Lightbox from "react-image-lightbox";
import "./index.css";
import ShowMenu from "../menu";
import AgentsList from "../agent_list";
import API from "../../../../api";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import Footer from "../../home/footer/index";

class ShowSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      credits: 0,
      credit_disabled: false,
      credit_transaction_disabled: false,
      params: "",
      dataList: "",
      tourKey: 0,
      prices: [],
      infant_prices: [],
      sums: 0,
      infant_sums: 0,
      dta: 0,
      infant_dta: 0,
      visa_fee: 0,
      subtract_others: 0,
      insurance_rebate: 0,
      subtraction: 0,
      infant_subtraction: 0,
      pre_commisions_total: 0,
      compulsory_additional_fee: 0,
      addon_others: 0,
      addon: 0,
      deposit: 0,
      single_supplement: 0,
      pax_count: 0,
      infant_count: 0,
      total: 0,
      infant_total: 0,
      grand_total: 0,
      service_fee: 0,
      final_price: 0,
      dta_single_supp: 0,
      total_single_supp: 0,
      infant_visa_fee: 0,
      infant_subtract_others: 0,
      infant_insurance_rebate: 0,
      payment_type: "",
      photoIndex: 0,
      isOpenSelectedPhotos: false,
      isOpenAllPhotos: false,
      fare_type_selected: "",
      fare_type: "",
      user: [],
      agents: [],
      isModalOpen: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initializeData();
  }

  componentDidUpdate(prevProps) {
    let hasPathChange = this.props.location.pathname !== prevProps.location.pathname
    let hasSearchChange = this.props.location.search !== prevProps.location.search 
    let hasUrlChange = hasPathChange || hasSearchChange
    if (hasUrlChange) {
      this.initializeData();
    }
  }

  async initializeData(){
    this.setState({
      loading: true,
    })

    await this.userFetch();
    if (!is_logged_in) {
      API.get("./api/v1/users/agents").then(function (res) {
        console.log(res.data);
        that.setState({
          agents: res.data.agents,
        });
      });
    }

    let url = "./api/v1" + this.props.location.pathname;
    var that = this;
    const params =
      that.props.location.search != ""
        ? that.props.location.search
        : that.props.location.pathname;

    API.get(url + params)
      .then(function (response) {
        console.log(response);
        if (response.status === 404) {
          console.log("Error 404");
          that.setState({
            loading: false,
            isModalOpen: true,
          });
        } else {
          console.log(response, "------------res");
          //check which selected date is applied, then flag it selected in frontend
          const urlParams = new URLSearchParams(params);
          const selectedDate = urlParams.get("depart_date");
          const dataItems = response.data;
          let tourKey

          for (var i = 0; i < dataItems.tours.length; i++) {
            dataItems.tours[response.data.tours[i].code] = response.data.tours[i];

            if (response.data.tours[i].departure_date === selectedDate) {
              tourKey = response.data.tours[i].code
            }
            if (selectedDate === '') {
              tourKey = response.data.tours[i].code
            }
          }

          if (selectedDate === '' || response.data && response.data.tours.length > 0 && tourKey == 0) {
            tourKey = response.data.tours[0].code
          }

          that.setState({
            dataList: dataItems,
            loading: false,
            params: params,
            tourKey: tourKey
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(
          "Tour / Itinerary Data Is Not Found, You Will Be Redirected To The Homepage"
        );
        window.location.href = "#/";
      })
      .then(function () {
        that.handleQuantity(that.state.prices, that.state.infant_prices);
      });
  }

  getCredits = (value, isCreditTransactionDisabled = false) => {
    this.setState(
      {
        credits: value,
        credit_transaction_disabled: isCreditTransactionDisabled
      },
      () => {
        this.handleUserCredits();
      }
    );
  };

  // banklistFetch() {
  //     let url = './api/v1/payments/payment_method';
  //     var that = this;

  //     API.get(url)
  //         .then(function (response) {
  //             // console.log(response, '------------res banklist');
  //             that.setState({
  //               credits: response.data.credits,
  //             }, () => { that.handleUserCredits(); })
  //         })
  //         .catch(function (error) {
  //             console.log(error);
  //         })
  //         .then(function () {
  //             // always executed
  //         });
  // }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleCallback = (key) => {
    let url = "./api/v1" + this.props.location.pathname;
    var that = this;

    that.setState({
      loading: true,
      isModalOpen: true,
    });

    let params = that.state.params;
    let id = that.state.dataList.id;
    if(typeof params == 'string'){
      params =  params.replace('/series/' + id, '')
    }
   
    const urlParams = new URLSearchParams(params);
    urlParams.set("depart_date", that.state.dataList.tours[key].departure_date);

    that.props.history.push({
      pathname: '/series/' + id,
      search: "?" + urlParams.toString()
    });

    that.setState({
      loading: false,
      params: params,
      tourKey: key,
    });
  };

  handleCustomerDeposit = (value) => {
    this.setState(
      {
        fare_type_selected: value,
      },
      () => {
        this.handleDeposit(value);
      }
    );
  };

  handlePaymentType = (value, fare_type_selected) => {
    this.setState(
      {
        payment_type: value,
        fare_type_selected: fare_type_selected,
      },
      () => {
        this.handleQuantity(this.state.prices, this.state.infant_prices);
      }
    );
  };

  handleDeposit = (value) => {
    let deposit = 0;

    if (value === "customer_deposit") {
      deposit =
        this.state.dataList.tours &&
        (this.state.dataList.tours[this.state.tourKey].deposit +
          this.state.dataList.tours[this.state.tourKey].extra_deposit) *
          this.state.pax_count;
    } else {
      deposit =
        this.state.dataList.tours &&
        this.state.dataList.tours[this.state.tourKey].deposit *
          this.state.pax_count;
    }

    this.setState(
      {
        deposit: deposit,
      },
      () => {
        this.handleQuantity(this.state.prices, this.state.infant_prices);
      }
    );
  };

  handleQuantity = (value, infant_value) => {
    let sums = 0;
    let infant_sums = 0;
    let dta = 0;
    let infant_dta = 0;
    let visa_fee = 0;
    let subtract_others = 0;
    let insurance_rebate = 0;
    let infant_visa_fee = 0;
    let infant_subtract_others = 0;
    let infant_insurance_rebate = 0;
    let subtraction = 0;
    let infant_subtraction = 0;
    let compulsory_additional_fee = 0;
    let addon_others = 0;
    let addon = 0;
    let deposit = 0;
    let single_supplement = 0;
    let pre_commisions_total = 0;
    let pax_count = 0;
    let infant_count = 0;
    let total = 0;
    let infant_total = 0;
    let grand_total = 0;
    let fare_type =
      this.state.dataList.tours &&
      moment(
        this.state.dataList.tours[this.state.tourKey].deposit_date,
        "DD-MM-YYYY"
      ).isAfter()
        ? "deposit"
        : "full fare";

    value.map((price) => {
      // sums
      sums += price.price * price.quantity;
      // pax
      pax_count += price.quantity;
      // subtraction
      if (
        this.state.user.discount_available &&
        !this.state.fare_type_selected.includes("customer") &&
        (!this.state.payment_type.includes("fpx") ||
          !this.state.payment_type.includes("public_bank"))
      ) {
        visa_fee =
          this.state.dataList.tours &&
          this.state.dataList.tours[this.state.tourKey].visa_fee * pax_count;
        // pre_commisions
        this.state.dataList.tours &&
        this.state.dataList.tours[this.state.tourKey].pre_commisions.map(
          (pre_commision) => {
            if (pre_commision.category == "fixed") {
              pre_commisions_total += pre_commision.amount * price.quantity;
            } else if (pre_commision.category == "percentage") {
              pre_commisions_total +=
                ((sums - dta) * pre_commision.amount * price.quantity) /
                100;
            }
          }
        );

        subtraction += visa_fee + pre_commisions_total;

        if (this.state.user.collect_dta) {
          dta += 0;
        } else {
          // dta
          dta += price.dta * price.quantity;
          // subtract_others
          subtract_others +=
            this.state.dataList.tours &&
            this.state.dataList.tours[this.state.tourKey].subtract_others *
              price.quantity;
          // insurance_rebate
          insurance_rebate +=
            this.state.dataList.tours &&
            this.state.dataList.tours[this.state.tourKey].insurance_rebate *
              price.quantity;
          subtraction = dta + insurance_rebate + subtract_others;
        }
      }

      // Add on
      compulsory_additional_fee =
        this.state.dataList.tours &&
        this.state.dataList.tours[this.state.tourKey]
          .compulsory_additional_fee * pax_count;
      addon_others =
        this.state.dataList.tours &&
        this.state.dataList.tours[this.state.tourKey].addon_others * pax_count;
      addon = compulsory_additional_fee + addon_others;

      // non-category fee
      // single_supplement = pax_count > 1 ? 0 : this.state.dataList.tours && this.state.dataList.tours[this.state.tourKey].single_supplement_price
      if (!this.state.user.is_guest_customer && this.state.fare_type_selected === "customer_deposit") {
        deposit =
          this.state.dataList.tours &&
          (this.state.dataList.tours[this.state.tourKey].deposit +
            this.state.dataList.tours[this.state.tourKey].extra_deposit) *
            pax_count;
      } else {
        deposit =
          this.state.dataList.tours &&
          this.state.dataList.tours[this.state.tourKey].deposit * pax_count;
      }

      // customer deposit for deposit for guest customer user
      if (this.state.user.is_guest_customer) {
        // TODO: console log to be removed
        console.log('customer deposit', this.state.dataList.tours[this.state.tourKey].customer_deposit)
        deposit = this.state.dataList.tours &&
                  this.state.dataList.tours[this.state.tourKey].customer_deposit * pax_count;
      }

      // total
      total = sums - subtraction + single_supplement + addon;
    });

    infant_value.map((price) => {
      // sums
      infant_sums += price.price * price.quantity;
      // pax
      infant_count += price.quantity;

      if (price.price > 0) {
        // subtraction
        if (
          !this.state.fare_type_selected.includes("customer") &&
          (!this.state.payment_type.includes("fpx") ||
            !this.state.payment_type.includes("public_bank"))
        ) {
          infant_visa_fee =
            this.state.dataList.tours &&
            this.state.dataList.tours[this.state.tourKey].visa_fee *
              infant_count;
          infant_subtract_others =
            this.state.dataList.tours &&
            this.state.dataList.tours[this.state.tourKey].subtract_others *
              infant_count;
          infant_subtraction = infant_visa_fee + infant_subtract_others;
        }
      }

      // total
      infant_total = infant_sums - infant_subtraction;
    });

    grand_total = total + infant_total;

    this.setState(
      {
        prices: value,
        infant_prices: infant_value,
        sums: sums,
        infant_sums: infant_sums,
        dta: dta,
        infant_dta: dta,
        visa_fee: visa_fee,
        subtract_others: subtract_others,
        insurance_rebate: insurance_rebate,
        infant_visa_fee: infant_visa_fee,
        infant_subtract_others: infant_subtract_others,
        infant_insurance_rebate: infant_insurance_rebate,
        subtraction: subtraction,
        infant_subtraction: infant_subtraction,
        pre_commisions_total: pre_commisions_total,
        compulsory_additional_fee: compulsory_additional_fee,
        addon_others: addon_others,
        addon: addon,
        deposit: deposit,
        single_supplement: single_supplement,
        pax_count: pax_count,
        infant_count: infant_count,
        total: total,
        infant_total: infant_total,
        grand_total: grand_total,
        fare_type: fare_type,
      },
      () => {
        this.handleUserCredits();
      }
    );
  };

  handleUserCredits() {
    // var final_price = this.state.dataList.tours && this.state.dataList.tours[this.state.tourKey].guaranteed_departure === false ? this.state.deposit : this.state.total
    if (this.state.fare_type_selected != "") {
      if (this.state.fare_type_selected.includes("deposit")) {
        var final_price = this.state.deposit;
      } else {
        var final_price = this.state.grand_total;
      }
    } else {
      var final_price =
        this.state.dataList.tours &&
        moment(
          this.state.dataList.tours[this.state.tourKey].deposit_date,
          "DD-MM-YYYY"
        ).isAfter()
          ? this.state.deposit
          : this.state.grand_total;
    }
    var service_fee = this.state.dataList.cc_rate * final_price;

    if (this.state.credit_transaction_disabled) {
      return this.setState({
        credit_disabled: true,
        service_fee: service_fee,
        final_price: final_price,
      })
    }

    if (this.state.credits >= final_price) {
      this.setState({
        credit_disabled: false,
        service_fee: service_fee,
        final_price: final_price,
      });
    } else {
      this.setState({
        credit_disabled: true,
        service_fee: service_fee,
        final_price: final_price,
      });
    }
  }

  handleTotalSupp = (value) => {
    this.setState({
      total_single_supp: value,
    });
  };

  handleSelectPhoto = (e, selectedPhotoIndex) => {
    e.preventDefault();
    this.setState({
      isOpenSelectedPhotos: true,
      photoIndex: selectedPhotoIndex,
    });
  };

  async userFetch() {
    const that = this;

    await API.get("./api/v1/users/details")
      .then(function (response) {
        that.setState({
          user: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  render() {
    const {
      credit_disabled,
      credits,
      loading,
      tourKey,
      dataList,
      sums,
      dta,
      visa_fee,
      subtract_others,
      insurance_rebate,
      subtraction,
      pre_commisions_total,
      compulsory_additional_fee,
      addon_others,
      addon,
      total,
      deposit,
      single_supplement,
      pax_count,
      prices,
      payment_type,
      infant_prices,
      infant_sums,
      infant_dta,
      infant_subtraction,
      infant_count,
      infant_total,
      grand_total,
      infant_visa_fee,
      infant_subtract_others,
      infant_insurance_rebate,
      user,
      fare_type_selected,
      fare_type,
      isModalOpen,
    } = this.state;

    return (
      <div className={homepage_new.suka === "false" ? "" : "suka"}>
        <ShowMenu />
        <div id="ShowSeries">
          <div>
            <div className="banner">
              <img
                className="bannerImage"
                src={
                  dataList.images === undefined || dataList.images.length == 0
                    ? homepage_new.default_show_header
                    : dataList.images[0]
                }
              />
            </div>
            <div id="overlay"></div>

            <div className="logo_top">
              <Link to="/">
                <div className="logo_warp">
                  <img src={homepage_new.app_logo} alt="ice" />
                  <span>
                    {homepage_new.suka === "false" ? homepage_new.app_name : ""}
                  </span>
                </div>
              </Link>
            </div>

            <div className="img_lightbox">
              {this.state.dataList.images &&
                this.state.dataList.images.length > 1 && (
                  <div className="img_cantainer">
                    {this.state.dataList.images &&
                      this.state.dataList.images[0] && (
                        <div className="img_warp">
                          <a
                            href="#/"
                            onClick={(e) => this.handleSelectPhoto(e, 0)}
                          >
                            <img src={this.state.dataList.images[0]} />
                          </a>
                        </div>
                      )}
                    {this.state.dataList.images &&
                      this.state.dataList.images[1] && (
                        <div className="img_warp">
                          <a
                            href="#/"
                            onClick={(e) => this.handleSelectPhoto(e, 1)}
                          >
                            <img src={this.state.dataList.images[1]} />
                          </a>
                        </div>
                      )}
                    <div className="clear"></div>
                  </div>
                )}
              {this.state.dataList.images &&
                this.state.dataList.images.length > 1 && (
                  <Button
                    type="primary"
                    onClick={() => this.setState({ isOpenAllPhotos: true })}
                  >
                    {homepage_new.suka === "false"
                      ? "VIEW MORE"
                      : "INFO LANJUT"}
                  </Button>
                )}
              {this.state.isOpenAllPhotos && (
                <Lightbox
                  mainSrc={this.state.dataList.images[this.state.photoIndex]}
                  nextSrc={
                    this.state.dataList.images[
                      (this.state.photoIndex + 1) %
                        this.state.dataList.images.length
                    ]
                  }
                  prevSrc={
                    this.state.dataList.images[
                      (this.state.photoIndex +
                        this.state.dataList.images.length -
                        1) %
                        this.state.dataList.images.length
                    ]
                  }
                  onCloseRequest={() =>
                    this.setState({ isOpenAllPhotos: false })
                  }
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (this.state.photoIndex +
                          this.state.dataList.images.length -
                          1) %
                        this.state.dataList.images.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex:
                        (this.state.photoIndex + 1) %
                        this.state.dataList.images.length,
                    })
                  }
                />
              )}
            </div>
          </div>
          <Spin size="large" spinning={loading}>
            <div className="warppage">
              <LeftPanel
                handleCallback={this.handleCallback}
                handleQuantity={this.handleQuantity}
                handlePaymentType={this.handlePaymentType}
                getCredits={this.getCredits}
                credit_disabled={credit_disabled}
                defaultImage={homepage_new.default_image}
                tourKey={tourKey} 
                dataList={dataList}
                total={total}
                deposit={deposit}
                credits={credits}
                service_fee={this.state.service_fee}
                final_price={this.state.final_price}
                handleTotalSupp={this.handleTotalSupp}
                instant_access_series={user.instant_access_series}
                handleCustomerDeposit={this.handleCustomerDeposit}
                bookable={is_logged_in}
                payable ={user.payable}
                discount_available={user.discount_available}
                is_guest_customer={user.is_guest_customer}
                collect_dta={user.collect_dta}
                tin_validation={user.tin_validation}
              />
              {is_logged_in ? (
                <RightPanel
                  loading={loading}
                  tourKey={tourKey}
                  sums={sums}
                  dta={dta}
                  visa_fee={visa_fee}
                  subtract_others={subtract_others}
                  insurance_rebate={insurance_rebate}
                  subtraction={subtraction}
                  pre_commisions_total={pre_commisions_total}
                  compulsory_additional_fee={compulsory_additional_fee}
                  addon_others={addon_others}
                  addon={addon}
                  total={total}
                  deposit={deposit}
                  single_supplement={single_supplement}
                  pax_count={pax_count}
                  prices={prices}
                  dataList={dataList}
                  payment_type={payment_type}
                  service_fee={this.state.service_fee}
                  infant_prices={infant_prices}
                  infant_sums={infant_sums}
                  infant_dta={infant_dta}
                  infant_subtraction={infant_subtraction}
                  infant_count={infant_count}
                  infant_total={infant_total}
                  grand_total={grand_total}
                  infant_visa_fee={infant_visa_fee}
                  infant_subtract_others={infant_subtract_others}
                  infant_insurance_rebate={infant_insurance_rebate}
                  fare_type_selected={fare_type_selected}
                  fare_type={fare_type}
                  discount_available={user.discount_available}
                  deposit_available={user.deposit_available}
                  final_payment_available={user.final_payment_available}
                />
              ) : (
                <AgentsList
                  className="rightSidebar"
                  agents={this.state.agents}
                />
              )}
              <div className="clear"></div>
            </div>
            <div className="res_panel">
              <Button
                type="primary"
                onClick={this.showDrawer}
                className="res_panel_btn"
              >
                {is_logged_in
                  ? "Summary"
                  : [
                      homepage_new.suka === "false"
                        ? "Contact Agent"
                        : "HUBUNGI AGENT KAMI",
                    ]}
              </Button>
              <Drawer
                title=""
                placement="right"
                closable={true}
                onClose={this.onClose}
                visible={this.state.visible}
              >
                {is_logged_in ? (
                  <RightPanel
                    loading={loading}
                    tourKey={tourKey}
                    dta={dta}
                    visa_fee={visa_fee}
                    subtract_others={subtract_others}
                    insurance_rebate={insurance_rebate}
                    subtraction={subtraction}
                    compulsory_additional_fee={compulsory_additional_fee}
                    addon_others={addon_others}
                    addon={addon}
                    total={total}
                    deposit={deposit}
                    single_supplement={single_supplement}
                    pax_count={pax_count}
                    prices={prices}
                    dataList={dataList}
                    service_fee={this.state.service_fee}
                    infant_prices={infant_prices}
                    infant_sums={infant_sums}
                    infant_dta={infant_dta}
                    infant_subtraction={infant_subtraction}
                    infant_count={infant_count}
                    infant_total={infant_total}
                    grand_total={grand_total}
                    infant_visa_fee={infant_visa_fee}
                    infant_subtract_others={infant_subtract_others}
                    infant_insurance_rebate={infant_insurance_rebate}
                    fare_type_selected={fare_type_selected}
                    fare_type={fare_type}
                  />
                ) : (
                  <AgentsList className="drawer" agents={this.state.agents} />
                )}
              </Drawer>
            </div>
          </Spin>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ShowSeries);
