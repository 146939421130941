import React, { Component } from 'react';
import { Drawer, Button, Spin } from 'antd';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel'
import './index.css';
import ShowMenu from '../menu';
import API from '../../../../api';
import { withRouter, Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import moment from 'moment';
import Footer from '../../home/footer/index';


class ShowVoucher extends Component {
    constructor(props) {
      super(props);
      this.state = {
        photoIndex: 0,
        dataList: [],
        isOpenSelectedPhotos: false,
        isOpenAllPhotos: false,
        // banklist: [],
        totalQty: 1,
        credits: 0,
        credit_disabled: false,
        credit_transaction_disabled: false,
        visible: false,
        loading: true,
        user: [],
        user_status: false,
        service_fee: 0,
        final_price: 0,
        payment_type: '',
        detailError: false,
        detailErrorMessage: '',
      };

      this.handleTotalQty = this.handleTotalQty.bind(this);
      this.handleUserCredits = this.handleUserCredits.bind(this);
    }

    componentDidMount() {
      // this.checkUser();
      // this.userFetch();
      // this.agentFetch();
      
      window.scrollTo(0, 0)

      const params = this.props.location.search;
      let url = './api/v1' + this.props.location.pathname;
      var that = this;
      console.log(params);

      API.get(url + params)
        .then(function (response) {
          console.log(response,'------------res');
          if (response.data.error) {
            that.setState({
              detailErrorMessage: response.data.error,
              detailError: true
            })
          }
          else {
            that.setState({
              dataList: response.data,
              loading: false
            }, () => { that.checkUser() })
          }
        })
        .catch(function (error) {
            console.log(error);
        })
        .then(function () {
            // always executed
        });
    }

    checkUser() {
      const that = this;

      API.get('./api/v1/users/details')
        .then(function (response) {
          console.log(response, '------------check user res');
          that.setState({
            user_status: response.data.status,
          })

          if (response.data.status) {
            that.userFetch();
          }
          else {
            that.agentFetch();
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        });
      // this.userFetch();
    };

    agentFetch() {
      const that = this;

      API.get('./api/v1/users/'+this.state.dataList.agent_id+'/agent_details')
        .then(function (response) {
          console.log(response, '------------agent res');
          that.setState({
            user: response.data,
          }, () => { that.handleTotalQty(1) })
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }

    userFetch() {
      const that = this;

      API.get('./api/v1/users/details')
        .then(function (response) {
          console.log(response, '------------user res');
          that.setState({
            user: response.data,
          }, () => { that.handleTotalQty(1) })
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }

    handleTotalQty(value) {
      var price_qty = this.state.dataList.price * this.state.totalQty;
      var service_fee = this.state.dataList.cc_rate * price_qty;

      this.setState({
        totalQty: value,
        final_price: price_qty,
        service_fee: service_fee,
      }, () => { this.handleUserCredits(); })
    }

    showDrawer = () => {
      this.setState({
        visible: true,
      });
    };

    onClose = () => {
      this.setState({
        visible: false,
      });
    };

    handleSelectPhoto = (e, selectedPhotoIndex) => {
      e.preventDefault();
      this.setState({ 
        isOpenSelectedPhotos: true, 
        photoIndex: selectedPhotoIndex
      })
    };

    getCredits = (value, isCreditTransactionDisabled = false) => {
      this.setState({
        credits: value,
        credit_transaction_disabled: isCreditTransactionDisabled
      }, () => { this.handleUserCredits(); })
    }

    handleUserCredits() {
      var price_qty = this.state.dataList.price * this.state.totalQty;

      if (this.state.credit_transaction_disabled) {
        return this.setState({
          credit_disabled: true
        })
      }

      if (this.state.credits >= price_qty) {
        this.setState({
          credit_disabled: false, 
        })
      }
      else {
        this.setState({
          credit_disabled: true,   
        })
      }
    }

    handlePaymentType = (value) => {
      this.setState({
        payment_type: value
      })
    }

    render() {
        const {loading} = this.state;

        return (
            <div>
              <div id="ShowVoucher">
                  <div className="banner">
                  {
                     this.state.dataList.images && (
                       <img className="bannerImage" src={this.state.dataList.images !== '' ? this.state.dataList.images[0] : homepage_new.default_show_header} />
                     )
                  }
                  </div>
                  <div id="overlay"></div>
                  {/* <div className="logo_top">
                      <Link to="/">
                          <div className="logo_warp">
                              <img src={homepage_new.app_logo} alt="ice" />
                              <span>TRAVELB2B</span>
                          </div>
                      </Link>
                  </div> */}
                  {
                    this.state.dataList.voucher_entitlements && this.state.dataList.voucher_entitlements.length > 0 && this.state.dataList.images && this.state.dataList.images.length > 1 ? (
                      <div className="img_lightbox">
                        <div className="img_cantainer">
                          {
                            this.state.dataList.images[1] && (
                              <div className="img_warp">
                                <a href="#/" onClick={e => this.handleSelectPhoto(e, 1)}><img src={this.state.dataList.images[1]} /></a></div>
                            )
                          }
                          {
                            this.state.dataList.images[2] && (
                              <div className="img_warp"><img src={this.state.dataList.images[2]} /></div>
                            )
                          }
                          {this.state.isOpenSelectedPhotos && (
                            <Lightbox
                              mainSrc={this.state.dataList.images[this.state.photoIndex]}
                              onCloseRequest={() => this.setState({ isOpenSelectedPhotos: false })}
                            />
                          )}
                          <div className="clear"></div>
                        </div>
                        <Button type="primary" onClick={() => this.setState({ isOpenAllPhotos: true })}>VIEW MORE</Button>
                        {this.state.isOpenAllPhotos && (
                          <Lightbox
                            mainSrc={this.state.dataList.images[this.state.photoIndex]}
                            nextSrc={this.state.dataList.images[(this.state.photoIndex + 1) % this.state.dataList.images.length]}
                            prevSrc={this.state.dataList.images[(this.state.photoIndex + this.state.dataList.images.length - 1) % this.state.dataList.images.length]}
                            onCloseRequest={() => this.setState({ isOpenAllPhotos: false })}
                            onMovePrevRequest={() =>
                              this.setState({
                                photoIndex: (this.state.photoIndex + this.state.dataList.images.length - 1) % this.state.dataList.images.length,
                              })
                            }
                            onMoveNextRequest={() =>
                              this.setState({
                                photoIndex: (this.state.photoIndex + 1) % this.state.dataList.images.length,
                              })
                            }
                          />
                        )}
                      </div>
                    ) : (null)
                  }

                  <Spin size="large" spinning={loading}>
                      <div className="warppage">
                          <LeftPanel dataList={this.state.dataList} handleTotalQty={this.handleTotalQty} credit_disabled={this.state.credit_disabled} credits={this.state.credits} loading={this.state.loading} getCredits={this.getCredits} userStatus={this.state.user_status} handlePaymentType={this.handlePaymentType} final_price={this.state.final_price} service_fee={this.state.service_fee} detailErrorMessage={this.state.detailErrorMessage} detailError={this.state.detailError} />
                          <RightPanel dataList={this.state.dataList} totalQty={this.state.totalQty} loading={this.state.loading} final_price={this.state.final_price} service_fee={this.state.service_fee} payment_type={this.state.payment_type} />
                          <div className="clear"></div>
                      </div>
                      <div className="res_panel">
                          <Button type="primary" onClick={this.showDrawer} className="res_panel_btn">
                              Summary
                       </Button>
                          <Drawer
                              title=""
                              placement="right"
                              closable={true}
                              onClose={this.onClose}
                              visible={this.state.visible}
                          >
                              <RightPanel dataList={this.state.dataList} totalQty={this.state.totalQty} loading={this.state.loading} final_price={this.state.final_price} service_fee={this.state.service_fee} payment_type={this.state.payment_type} />
                          </Drawer>
                      </div>
                  </Spin>
              </div>
              <Footer />
            </div>
        );
    }
}

export default withRouter(ShowVoucher);
