import React, { Component } from 'react';
import { Drawer, Button, Spin } from 'antd';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel'
import './index.css';
import ShowMenu from '../menu';
import API from '../../../../api';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import img from "../../../../../images/roamin/01.png";
import ice from '../../../../../images/logo.png';
import defaultImage from '../../../../../images/default-image.png';
import headerImage from '../../../../../images/show_header.png';
import Footer from '../../home/footer/index';
import qs from 'qs';

const dateFormat = 'DD/MM/YYYY';

class ShowLandTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      params: '',
      credits: 0,
      credit_disabled: false,
      credit_transaction_disabled: false,
      dataList: '',
      user: '',
      prices: [],
      voucher_prices: [],
      foc_prices: [],
      compulsory_charges: 0,
      dta: 0,
      dta_adult: 0,
      dta_single_supp: 0,
      dta_child: 0,
      dta_voucher_adult: 0,
      dta_voucher_child: 0,
      dta_voucher_single_supp: 0,
      pax_count: 0,
      total: 0,
      deposit: 0,
      single_supplement: 0,
      voucher_paid: 0,
      departure_date: '',
      cut_off_date: '',
      price_category: '',
      service_fee: 0,
      final_price: 0,
      payment_type: '',
      single_supp: 0,
      total_single_supp: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const params = this.props.location.search;
    let url = './api/v1' + this.props.location.pathname;
    var that = this;

    API.get(url + params)
      .then(function (response) {
        that.setState({
          dataList: response.data,
          loading: false,
          params: params
        })
      })
      .catch(function (error) {
          console.log(error);
      })
      .then(function () {
          // always executed
      });

    this.userFetch();
  }

  userFetch() {
    const that = this;

    API.get('./api/v1/users/details')
      .then(function (response) {
        // console.log(response, '------------res user');
        that.setState({
          user: response.data,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  handleTotalQty = (value, voucher_value) => {
    let sums = 0;
    let voucher_sums = 0;
    let compulsory_charges = 0;
    let voucher_compulsory_charges = 0;
    let total_charges = 0;
    let dta = 0;
    let dta_voucher = 0;
    let dta_adult = 0;
    let dta_single_supp = 0;
    let dta_voucher_adult = 0;
    let dta_child = 0;
    let dta_voucher_child = 0;
    let dta_voucher_single_supp = 0;
    let total = 0;
    let grand_total = 0;
    let voucher_total = 0;
    let deposit = 0;
    let single_supplement = 0;
    let pax_count = 0;
    let dta_prices = this.state.dataList.dta_prices;
    let free_of_charge = this.state.dataList.free_of_charge;
    // let free_of_charge = this.state.dataList.free_of_charge !== 0 ? +(this.state.dataList.free_of_charge.match(/(\d+)/)[0]) : 0;
    let pax_charges = 0;
    let group_charges = 0;
    let voucher_paid = 0;
    let service_fee = 0;
    let final_price = 0;
    let unit_price = 0;
    let total_dta = 0;

    this.state.dataList.compulsory_charges.map((charge, i) => {
      if (charge.type === "Pax") {
        pax_charges += +charge.price;
      }
      else {
        group_charges += +charge.price;
      }
    });

    // FOC calculation
    let total_qty = 0
    var foc_contact = ''

    value.map((p, j) => { total_qty += p.quantity })
    if ((total_qty >= free_of_charge) && free_of_charge > 0) {
      var adult = value.find(x => x.name.includes('adult'))
      value.find(x => x.name.includes('adult')).quantity = adult.quantity - 1
      foc_contact = adult
    }

    voucher_value.map((voucher) => {
      // Pax
      pax_count += voucher.quantity

      // Charges per pax
      voucher_compulsory_charges += pax_charges * voucher.quantity;

      // DTA
      if (this.state.user.collect_dta) {
        dta += 0
      }
      else {
        if (voucher.name === "adult") {
          dta_voucher_adult += voucher.dta * voucher.quantity;
        }
        else if (voucher.name === "single_supplement") {
          dta_voucher_single_supp += voucher.dta * voucher.quantity;
        }
        else {
          dta_voucher_child += voucher.dta * voucher.quantity;
        }
        dta_voucher = dta_voucher_adult + dta_voucher_child + dta_voucher_single_supp
      }

      // Paid price
      voucher_paid += voucher.paid * voucher.quantity

      // Total
      voucher_total = (voucher_sums += voucher.price * voucher.quantity) + voucher_compulsory_charges
    });

    value.map((price) => {
      // Pax
      pax_count += price.quantity

      // Charges per pax
      compulsory_charges += pax_charges * price.quantity;

      // DTA
      if (this.state.user.collect_dta) {
        dta +=  0
      }
      else {
        if (price.name === "adult") {
          dta_adult += price.dta * price.quantity;
        }
        else if (price.name === "single_supplement") {
          dta_single_supp += price.dta * price.quantity;
        }
        else {
          dta_child += price.dta * price.quantity;
        }
        dta = dta_adult + dta_child + dta_single_supp
      } 

      // Total
      total = (sums += price.price * price.quantity) + compulsory_charges
    });

    // DTA
    total_dta = dta_voucher + dta

    // Non-category fee
    // single_supplement += pax_count > 1 ? 0 : this.state.single_supp

    // Charges per group
    total_charges = compulsory_charges + voucher_compulsory_charges + group_charges
    
    // compulsory_charges += group_charges
    grand_total = total + voucher_total - total_dta + group_charges - voucher_paid

    // Deposit
    if (this.state.dataList.deposit > 0) {
      if (this.state.dataList.deposit_type === "Fixed") {
        deposit = this.state.dataList.deposit * pax_count
      }
      else {
        deposit = (this.state.dataList.deposit / 100) * grand_total
      }
    }

    if (!moment().isAfter(moment(this.state.cut_off_date).subtract(2, 'days').format("YYYY-MM-DD"))) {
      service_fee = this.state.dataList.cc_rate * deposit;
      final_price = deposit;
    }
    else {
      service_fee = this.state.dataList.cc_rate * grand_total;
      final_price = grand_total;
    }
    
    this.setState({
      prices: value,
      voucher_prices: voucher_value,
      foc_prices: foc_contact,
      pax_count: pax_count,
      compulsory_charges: total_charges,
      dta: total_dta,
      dta_adult: dta_adult,
      dta_child: dta_child,
      dta_single_supp: dta_single_supp,
      dta_voucher_adult: dta_voucher_adult,
      dta_voucher_child: dta_voucher_child,
      dta_voucher_single_supp: dta_voucher_single_supp,
      total: grand_total,
      deposit: deposit,
      service_fee: service_fee,
      final_price: final_price,
      single_supplement: single_supplement,
      voucher_paid: voucher_paid,
    }, () => { this.handleUserCredits(); })
  }

  handleUserCredits() {
    if (this.state.credit_transaction_disabled) {
      return this.setState({
        credit_disabled: true
      })
    }

    if (this.state.credits >= this.state.total) {
      this.setState({
        credit_disabled: false,
      })
    }
    else {
      this.setState({
        credit_disabled: true,
      })
    }
  }

  handleSingleSupp = (value) => {
    this.setState({
      single_supp: value
    })
  }

  handleTotalSupp = (value) => {
    this.setState({
      total_single_supp: value
    })
  }

  handlePaymentType = (value) => {
    this.setState({
      payment_type: value
    })
  }

  handleDepartDate = (value) => {
    this.setState({
      departure_date: value,
    })
  }

  handleCutOffDate = (value) => {
    this.setState({
      cut_off_date: value,
    })
  }

  handlePriceCategory = (value) => {
    this.setState({
      price_category: value
    })
  }

  getCredits = (value, isCreditTransactionDisabled = false) => {
    this.setState({
      credits: value,
      credit_transaction_disabled: isCreditTransactionDisabled
    }, () => { this.handleUserCredits(); })
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleCallback = (key) => {
    this.setState({
      tourKey: key
    });
  };

  render() {
    const { loading, dataList, credits, credit_disabled, departure_date, compulsory_charges, total, pax_count, prices, price_category, dta, dta_child, dta_adult, cut_off_date, deposit, single_supplement, foc_prices, voucher_prices, dta_voucher_adult, dta_voucher_child, voucher_paid, service_fee, payment_type, final_price, single_supp, total_single_supp, dta_single_supp, dta_voucher_single_supp } = this.state
    return (
      <div>
        <ShowMenu /> 
        <div id="ShowLandTour">
          <div className="banner">
            <img className="bannerImage" src={ dataList.images == '' ? headerImage : dataList.images} />
          </div>
          <div className="logo_top">
            <Link to="/">
              <div className="logo_warp">
                <img src={ice} alt="ice" />
                <span>TRAVELB2B</span>
              </div>
            </Link>
          </div>
          <Spin size="large" spinning={loading}>
            <div className="warppage">
              <LeftPanel handleTotalQty={this.handleTotalQty} handlePriceCategory={this.handlePriceCategory} getCredits={this.getCredits} handleDepartDate={this.handleDepartDate} handleCutOffDate={this.handleCutOffDate} credit_disabled={credit_disabled} defaultImage={defaultImage} dataList={dataList} departure_date={departure_date} credits={credits} service_fee={service_fee} total={total} handlePaymentType={this.handlePaymentType} final_price={final_price} handleSingleSupp={this.handleSingleSupp} handleTotalSupp={this.handleTotalSupp} />
              <RightPanel loading={loading} dataList={dataList} departure_date={departure_date} compulsory_charges={compulsory_charges} pax_count={pax_count} prices={prices} total={total} price_category={price_category} dta={dta} dta_child={dta_child} dta_adult={dta_adult} cut_off_date={cut_off_date} deposit={deposit} single_supplement={single_supplement} foc_prices={foc_prices} voucher_prices={voucher_prices} dta_voucher_child={dta_voucher_child} dta_voucher_adult={dta_voucher_adult} voucher_paid={voucher_paid} collectDta={this.state.user.collect_dta} service_fee={service_fee} payment_type={payment_type} final_price={final_price} single_supp={single_supp} total_single_supp={total_single_supp} dta_single_supp={dta_single_supp} dta_voucher_single_supp={dta_voucher_single_supp} />
              <div className="clear"></div>
            </div>
            <div className="res_panel">
              <Button type="primary" onClick={this.showDrawer} className="res_panel_btn">
                Summary
              </Button>
              <Drawer
                title=""
                placement="right"
                closable={true}
                onClose={this.onClose}
                visible={this.state.visible}
              >
                <RightPanel loading={loading} dataList={dataList} departure_date={departure_date} compulsory_charges={compulsory_charges} pax_count={pax_count} prices={prices} total={total} price_category={price_category} dta={dta} dta_child={dta_child} dta_adult={dta_adult} dta_voucher_child={dta_voucher_child} dta_voucher_adult={dta_voucher_adult} cut_off_date={cut_off_date} deposit={deposit} single_supplement={single_supplement} foc_prices={foc_prices} voucher_prices={voucher_prices} voucher_paid={voucher_paid} collectDta={this.state.user.collect_dta} service_fee={service_fee} final_price={final_price} single_supp={single_supp} total_single_supp={total_single_supp} dta_single_supp={dta_single_supp} dta_voucher_single_supp={dta_voucher_single_supp} />
              </Drawer>
            </div>
          </Spin>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ShowLandTour);
